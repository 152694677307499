import { useTranslation } from "react-i18next";
import { getStatusColor } from "../../utils/caseUtils";

import { CaseStatus } from "../../api";

import clsx from "clsx";
import { createStyles, makeStyles } from "@material-ui/core";

interface CaseStatusProps {
  status?: CaseStatus;
}

const useStyles = makeStyles(() =>
  createStyles({
    shape: {
      width: 12,
      height: 12,
    },
    shapeCircle: {
      display: "inline-block",
      borderRadius: "50%",
    },
  })
);

export const Status = (props: CaseStatusProps) => {
  const { status } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  if (status === undefined) return null;

  const statusColor = getStatusColor(status);
  const statusText = t(`caseStatus.${status.toLowerCase()}` as any);

  return (
    <div>
      <div
        className={clsx(classes.shape, classes.shapeCircle)}
        style={{ backgroundColor: statusColor, marginRight: 10 }}
      />
      {statusText}
    </div>
  );
};
