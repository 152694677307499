import { Button } from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { CustomerDTO } from "../../api";
import { useAppDispatch } from "../../hooks/redux";
import { deleteCustomer } from "../../store/customersReducer";
import { ConfirmationDialog } from "../ConfirmDialog/ConfirmDialog";

interface DeleteCustomerProps {
  customerToDelete: CustomerDTO;
}

export const DeleteCustomer = (props: DeleteCustomerProps) => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleClose = () => {
    setDialogOpen(false);
  };
  const handleOpen = () => {
    setDialogOpen(true);
  };
  const handleDeletion = () => {
    dispatch(deleteCustomer(props.customerToDelete.id!))
      .then(unwrapResult)
      .then(() => {
        history.push("/admin/customers");
      });
  };

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Button color="secondary" variant="contained" onClick={handleOpen}>
        {t("deleteCustomer.deleteCustomerTitle")}
      </Button>
      <ConfirmationDialog
        id={"deleteCustomer"}
        open={dialogOpen}
        onCancel={handleClose}
        onConfirm={handleDeletion}
        title={t("deleteCustomer.deleteCustomerTitle")}
        content={t("default:deleteCustomer.deleteCustomerContent")}
        confirmText={t("deleteCustomer.deleteCustomerYes")}
        cancelText={t("deleteCustomer.deleteCustomerNo")}
      ></ConfirmationDialog>
    </div>
  );
};
