import React, { useState } from "react";
import { DataGrid, GridColDef, GridRowData, GridValueFormatterParams } from "@material-ui/data-grid";
import { formatDateAndTime } from "../../utils/dateUtils";
import { makeStyles, Typography } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchCustomers } from "../../store/customersReducer";
import { Loading } from "../Loading/Loading";
import { CustomerDTO } from "../../api";
import { AddCustomer } from "./AddCustomer";

const useStyles = makeStyles((theme) => ({
  dataGridHolder: {
    height: "80vh",
    width: "90%",
  },
  root: {
    alignItems: "center",
    justifyContent: "center",
    width: "90%",
    padding: theme.spacing(3, 0, 3, 0),
  },
  customerTitle: {
    fontSize: 40,
    flexGrow: 1,
    fontWeight: "lighter",
    display: "flex",
    marginRight: theme.spacing(2),
  },
}));

const initialCustomerState: CustomerDTO[] = [];

export const Customers = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const customers = useAppSelector((state) => state.customers.all);
  const loading = useAppSelector(
    (state) => state.customers.status === "NOT_LOADED"
  );

  const [customerState, setCustomerState] = useState(initialCustomerState);
  useEffect(() => {
    if (customers.length === 0) {
      dispatch(fetchCustomers());
      setCustomerState(customers);
    }
  }, [customers, dispatch]);

  useEffect(() => {
    if (JSON.stringify(customers) !== JSON.stringify(customerState)) {
      dispatch(fetchCustomers());
      setCustomerState(customers);
    }
  }, [customers, customerState, dispatch]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("customers.customerName"),
      width: 300,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value?.toString().replace("#", ""),
    },
    {
      field: "id",
      headerName: "Id",
      width: 300,
    },
    {
      field: "numberOfActive",
      headerName: t("customers.noOfActiveCases"),
      width: 170,
      type: "number",
      valueGetter: (params) => {
        return params.row.caseStatistics.numberOfActive;
      },
    },
    {
      field: "lastModified",
      headerName: t("customers.lastUpdated"),
      width: 200,
      type: "dateTime",
      valueGetter: (params) => {
        if (params.row.caseStatistics.lastModified !== undefined)
          return formatDateAndTime(params.row.caseStatistics.lastModified);
      },
    },
    {
      field: "editButton",
      headerName: " ",
      width: 150,
      renderCell: (params: GridRowData) => (
        <Link
          to={`/admin/customer/settings/${params.id}`}
          style={{ textDecoration: "none" }}
        >
          <Button
            color="secondary"
            variant="contained"
            size="medium"
            style={{ marginLeft: 16 }}
          >
            {t("default:customers.edit")}
          </Button>
        </Link>
      ),
    },
    {
      field: "statsButton",
      headerName: " ",
      width: 150,
      renderCell: (params: GridRowData) => (
        <Link
          to={`/statistics/${params.id}/casetype`}
          style={{ textDecoration: "none" }}
        >
          <Button variant="outlined" size="medium" style={{ marginLeft: 16 }}>
            {t("menu.statistics")}
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <div>
      {loading ? <Loading /> : null}
      <Toolbar className={classes.root}>
        <Typography className={classes.customerTitle}>
          {t("default:customers.customers")}
        </Typography>
        <div>
          <AddCustomer
            customerList={customerState}
            setCustomerList={setCustomerState}
          ></AddCustomer>
        </div>
      </Toolbar>
      <div className={classes.dataGridHolder}>
        <DataGrid rows={customers} columns={columns} pageSize={100} />
      </div>
    </div>
  );
};
