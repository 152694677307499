/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CaseStatus {
    NEW = 'New',
    INITIAL_JUDGEMENT = 'Initial_Judgement',
    UNDER_INVESTIGATION = 'Under_Investigation',
    UNDER_MAIN_INVESTIGATION = 'Under_Main_Investigation',
    CLOSED = 'Closed',
    WRITTEN_OFF = 'Written_Off',
}