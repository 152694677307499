import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomerDTO, UserDTO } from "../../api";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { createCustomer } from "../../store/customersReducer";
import { Loading } from "../Loading/Loading";
import { CustomerCreated } from "./CustomerCreated";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    justifyContent: "center",
    width: "90%",
    padding: theme.spacing(3, 0, 3, 0),
  },
  dialogTitle: {
    fontSize: 25,
    marginTop: 10,
    marginBottom: 10,
  },
  dialogInfo: {
    fontSize: 18,
    marginTop: 10,
    marginBottom: 10,
  },
  noWhiteSpace: {
    fontSize: 12,
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 10,
    color: "red",
  },
}));

interface AddCustomerProps {
  customerList: CustomerDTO[];
  setCustomerList: Function;
}

export const AddCustomer = (props: AddCustomerProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const createdCustomer = useAppSelector(
    (state) => state.customers.createdCustomer
  );
  const loading = useAppSelector(
    (state) => state.customers.status === "NOT_CREATED"
  );
  const created = useAppSelector(
    (state) => state.customers.status === "CREATED"
  );

  const [newCustomerId, setCustomerId] = useState("");
  const [whiteSpaceError, setWhiteSpaceError] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setCustomerId("");
  };

  const handleAdd = () => {
    const initialUsers: UserDTO[] = [];
    const newCustomer: CustomerDTO = {
      id: newCustomerId,
      name: "",
      image: "",
      backgroundColor: "#FFFFFF",
      users: initialUsers,
    };
    setDialogOpen(false);
    dispatch(createCustomer(newCustomer));
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    setCustomerId(value);
    if (value.match(/\s/)) {
      setWhiteSpaceError(true);
    } else {
      setWhiteSpaceError(false);
    }
  };

  return (
    <div>
      {loading ? <Loading /> : null}
      {created ? (
        <CustomerCreated
          newCustomer={createdCustomer!}
          customerList={props.customerList}
          updateCustomerList={props.setCustomerList}
        />
      ) : null}
      <Button
        variant="outlined"
        color="default"
        size="large"
        style={{ marginLeft: 16 }}
        onClick={handleOpen}
      >
        {"+ " + t("addCustomer.newCustomer")}
      </Button>
      <Dialog open={dialogOpen}>
        <DialogContent>
          <Typography className={classes.dialogTitle}>
            {t("addCustomer.newCustomerTitle")}
          </Typography>
          <Typography className={classes.dialogInfo}>
            {t("addCustomer.newCustomerInfo")}
          </Typography>
          <DialogContentText>{t("customers.customerId")}</DialogContentText>
          <TextField
            helperText={t("addCustomer.noWhiteSpace")}
            error={whiteSpaceError}
            variant="outlined"
            size="medium"
            fullWidth
            value={newCustomerId}
            onChange={handleChange}
            style={{ width: "45vh", marginBottom: 10 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            {t("addCustomer.abortNewCustomer")}
          </Button>
          <Button
            onClick={handleAdd}
            color="primary"
            disabled={whiteSpaceError}
            autoFocus
          >
            {t("addCustomer.addNewCustomer")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
