export function shouldUseDarkModeFromColor(colorHex: string) {
  if (colorHex.length === 4) {
    colorHex = colorHex.replace(/^#(.)(.)(.)/i, "#$1$1$2$2$3$3");
  }

  const n = parseInt(colorHex.slice(1), 16);

  const r = (n & 0xff0000) >> 16;
  const g = (n & 0xff00) >> 8;
  const b = n & 0xff;

  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq <= 128;
}
