import React from "react";
import { DataGrid, GridColDef, GridRowData } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { RoleType, CustomerDTO, UserDTO } from "../../api";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { DeleteUser } from "./DeleteUser";
import { useAppSelector } from "../../hooks/redux";
import { userInfo } from "os";

const useStyles = makeStyles((theme) => ({
  dataGridHolder: {
    height: "50vh",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100vh",
    padding: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

interface UsersTableProps {
  users: UserDTO[];
  setCustomer: Function;
  customer: CustomerDTO;
}

export const UsersTable = (props: UsersTableProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  let tempCustomer: CustomerDTO = JSON.parse(JSON.stringify(props.customer));

  const signedInUserRole = useAppSelector((state) => state.auth.user!.customersAndRoles?.find(x => x.customerId === tempCustomer.id)?.role);

  const columns: GridColDef[] = [
    { field: "emailAddress", headerName: t("user.email"), width: 320 },
    { field: "firstName", headerName: t("user.firstName"), width: 220 },
    { field: "lastName", headerName: t("user.lastName"), width: 220 },
    {
      field: "role",
      headerName: t("user.role"),
      width: 300,
      renderCell: (params: GridRowData) => {
        const handleRoleChange = (
          event: React.ChangeEvent<{ value: unknown }>
        ) => {
          tempCustomer.users![params.rowIndex].customersAndRoles!.find(x => x.customerId === tempCustomer.id)!.role = event.target
            .value as RoleType;
          props.setCustomer(tempCustomer);
        };
        return (
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="RoleInput"></InputLabel>
            <Select
              id="RoleSelect"
              value={props.users[params.rowIndex].customersAndRoles!.find(x => x.customerId === tempCustomer.id)!.role}
              onChange={handleRoleChange}
              label={t("user.role")}
            >
              {signedInUserRole === RoleType.GLOBAL_ADMIN ? 
                <MenuItem value={RoleType.GLOBAL_ADMIN}>
                  {t("roles.globalAdmin")}
                </MenuItem> : 
                <MenuItem disabled value={RoleType.GLOBAL_ADMIN}>
                  {t("roles.globalAdmin")}
                </MenuItem>}
              {props.users[params.rowIndex].customersAndRoles!.find(x => x.customerId === tempCustomer.id)!.role === RoleType.GLOBAL_ADMIN && signedInUserRole !== RoleType.GLOBAL_ADMIN ? 
                <MenuItem disabled value={RoleType.ADMIN}>{t("roles.admin")}</MenuItem> : 
                <MenuItem value={RoleType.ADMIN}>{t("roles.admin")}</MenuItem>}
              {props.users[params.rowIndex].customersAndRoles!.find(x => x.customerId === tempCustomer.id)!.role === RoleType.GLOBAL_ADMIN && signedInUserRole !== RoleType.GLOBAL_ADMIN ? 
              <MenuItem disabled value={RoleType.USER}>{t("roles.user")}</MenuItem> :
              <MenuItem value={RoleType.USER}>{t("roles.user")}</MenuItem>}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "removeButton",
      headerName: " ",
      width: 150,
      renderCell: (params: GridRowData) => (
        <DeleteUser
          relatedUser={params}
          customer={props.customer}
          setCustomer={props.setCustomer}
        />
      ),
    },
  ];

  return (
    <DataGrid
      className={classes.dataGridHolder}
      columns={columns}
      rows={props.users}
      pageSize={10}
    ></DataGrid>
  );
};
