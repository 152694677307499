import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import React, { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as MsSignInButton } from "../Icons/ms-symbollockup_signin_light.svg";
import { useAppSelector } from "../../hooks/redux";
import { NoAccess } from "../MainApp/NoAccess";
import { AppBar, Button, Toolbar } from "@material-ui/core";
import { LanguageSwitcher } from "../LanguageSwitcher/LanguageSwitcher";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  title: {
    flexGrow: 1,
  },
}));

interface LogInProps {
  onLogin: (e: SyntheticEvent<Element, Event>) => void;
}

export default function LogIn(props: LogInProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const authStatus = useAppSelector((state) => state.auth.status);

  return (
    <div>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}></Typography>
          <LanguageSwitcher isSettingDefaultValue={false} />
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("logIn.title")}
          </Typography>
          <Box marginTop={4}>
            <MsSignInButton onClick={props.onLogin} />
          </Box>
          {authStatus === "NO_ACCESS" ? <NoAccess /> : null}
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://www.humanheart.se/" target="_blank">
        Human & Heart
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
