import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { ApiError, CaseOutputDTO, NewCaseOutputDTO, OpenAPI } from "../api";
import { IncidentCategory } from "../api/models/IncidentCategory";
import { newCaseInputDTOToFormData } from "../utils/caseUtils";

export type ReporterStatus = "INITIAL" | "LOADING" | "DONE" | "REJECTED";
export interface ReporterState {
  status: ReporterStatus;
  type?: IncidentCategory;
  date?: string;
  location?: string;
  metadata?: string;
  description?: string;
  attachment?: File;
  contactPhone?: string;
  contactEmail?: string;
  contactName?: string;
  addedId?: string;
  addedPassword?: string;
  validationErrors?: string[];
  createdCase?: CaseOutputDTO;
  personsInvolved?: string[];
  personsInvolvedLabel?: string;
  relation?: string;
  relationLabel?: string;
  witness?: string;
  witnessLabel?: string;
}

export const setMetadata = createAction<string>("reporter/setMetadata");
export const setType = createAction<IncidentCategory>("reporter/setType");
export const setDate = createAction<string>("reporter/setDate");
export const setLocation = createAction<string>("reporter/setLocation");
export const setDescription = createAction<string>("reporter/setDescription");
export const setContactPhone = createAction<string>("reporter/setContactPhone");
export const setContactEmail = createAction<string>("reporter/setContactEmail");
export const setContactName = createAction<string>("reporter/setContactName");
export const setValidationErrors = createAction<string[]>(
  "reporter/setValidationErrors"
);
export const setAttachment = createAction<File>("reporter/setAttachment");
export const clearAttachment = createAction("reporter/clearAttachment");
export const clearAll = createAction("reporter/clearAll");
export const setPersonsInvolved = createAction<string[]>(
  "reporter/setPersonsInvolved"
);
export const setPersonsInvolvedLabel = createAction<string>(
  "reporter/setPersonsInvolvedLabel"
);
export const setRelation = createAction<string>("reporter/setRelation");
export const setRelationLabel = createAction<string>(
  "reporter/setRelationLabel"
);
export const setWitness = createAction<string>("reporter/setWitness");
export const setWitnessLabel = createAction<string>("reporter/setWitnessLabel");

// Our generated API is having problems with the POST new case :(
export interface NewCaseInputDTO {
  CustomerId: string;
  IncidentCategory: IncidentCategory | undefined;
  Contact: string | undefined;
  Message: string | undefined;
  Attachments: File[] | null;
}

export const reportCase = createAsyncThunk(
  "reporter/reportCase",
  async (newCase: NewCaseInputDTO, thunkAPI) => {
    const url = OpenAPI.BASE + "/api/cases";
    const formData = newCaseInputDTOToFormData(newCase);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        return thunkAPI.rejectWithValue([response.status.toString()]);
      } else {
        const data = await response.json();
        return data as NewCaseOutputDTO;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(["general"]);
    }
  }
);

const initialState = {
  status: "INITIAL",
} as ReporterState;

export const reporterReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(reportCase.pending, (state) => {
      state.status = "LOADING";
    })
    .addCase(reportCase.fulfilled, (state, action) => {
      state.status = "DONE";
      state.createdCase = action?.payload?.case;
      state.addedId = action?.payload?.id ?? undefined;
      state.addedPassword = action?.payload?.password ?? undefined;
    })
    .addCase(reportCase.rejected, (state, action) => {
      state.status = "REJECTED";
      state.validationErrors = action.payload as string[];
    })
    .addCase(clearAll, () => initialState)
    .addCase(setType, (state, action) => {
      state.type = action.payload;
    })
    .addCase(setMetadata, (state, action) => {
      state.metadata = action.payload;
    })
    .addCase(setContactName, (state, action) => {
      state.contactName = action.payload;
    })
    .addCase(setValidationErrors, (state, action) => {
      state.validationErrors = action.payload;
    })
    .addCase(setDate, (state, action) => {
      state.date = action.payload;
    })
    .addCase(setLocation, (state, action) => {
      state.location = action.payload;
    })
    .addCase(setDescription, (state, action) => {
      state.description = action.payload;
    })
    .addCase(setContactPhone, (state, action) => {
      state.contactPhone = action.payload;
    })
    .addCase(setPersonsInvolved, (state, action) => {
      state.personsInvolved = action.payload;
    })
    .addCase(setRelation, (state, action) => {
      state.relation = action.payload;
    })
    .addCase(setWitness, (state, action) => {
      state.witness = action.payload;
    })
    .addCase(setPersonsInvolvedLabel, (state, action) => {
      state.personsInvolvedLabel = action.payload;
    })
    .addCase(setRelationLabel, (state, action) => {
      state.relationLabel = action.payload;
    })
    .addCase(setWitnessLabel, (state, action) => {
      state.witnessLabel = action.payload;
    })
    .addCase(setAttachment, (state, action) => {
      state.attachment = action.payload;
    })
    .addCase(clearAttachment, (state, action) => {
      state.attachment = undefined;
    })
    .addCase(setContactEmail, (state, action) => {
      state.contactEmail = action.payload;
    });
});
