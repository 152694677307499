import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import {
  AuditLogDTO,
  CustomerDTO,
  CustomersService,
  NewCustomerOutputDTO,
} from "../api";

export type CustomersStatus =
  | "LOADED"
  | "NOT_LOADED"
  | "TO_BE_DELETED"
  | "DELETED"
  | "NOT_UPDATED"
  | "UPDATED"
  | "NOT_CREATED" 
  | "CREATED" 
  | "REJECTED"
  | "LOADING_DETAILS"
  | "DETAILS_LOADED";

export type LogStatus = "LOG_NOT_LOADED" | "LOG_LOADED";

export type PassphraseStatus = "PASS_NOT_CHANGED" | "PASS_CHANGED" | "PASS_REJECTED";

interface CustomersState {
  status: CustomersStatus;
  logStatus: LogStatus;
  all: CustomerDTO[];
  details?: CustomerDTO;
  AuditLog: AuditLogDTO[];
  createdCustomer?: NewCustomerOutputDTO;
  passphraseStatus: PassphraseStatus;
  passphrase: string[];
}

export const fetchCustomers = createAsyncThunk(
  "customers/fetchAll",
  async () => {
    return await CustomersService.getCustomers();
  }
);

export const fetchCustomer = createAsyncThunk(
  "customers/fetchById",
  async (customerId: string) => {
    return await CustomersService.getCustomer(customerId);
  }
);

export const editCustomer = createAsyncThunk(
  "customers/editById",
  async (customer: CustomerDTO) => {
    return await CustomersService.updateCustomers(customer.id!, customer);
  }
);

export const createCustomer = createAsyncThunk(
  "customers/create",
  async (customer: CustomerDTO) => {
    return await CustomersService.createCustomer(customer);
  }
);
export const deleteCustomer = createAsyncThunk(
  "customers/delete",
  async (customerId: string) => {
    return await CustomersService.deleteCustomer(customerId);
  }
);

export const fetchUserLog = createAsyncThunk("customers/fetchUserLog", async (customerId: string) => {
    return await CustomersService.getAuditLogs(customerId);
})

export const generatePassphrase = createAsyncThunk("customers/passphrase", async ({customerId, customerToken}:{customerId: string, customerToken: string }) => {
  return await CustomersService.generateNewPassphrase(customerId, customerToken);
})

const initialState = {
    all: [],
    status: "NOT_LOADED",
    logStatus: "LOG_NOT_LOADED",
    details: {},
    AuditLog: [],
    passphraseStatus: "PASS_NOT_CHANGED",
    passphrase: []
} as CustomersState

export const customersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchCustomers.fulfilled, (state, action) => {
      state.status = "LOADED";
      state.all = action.payload;
    })
    .addCase(fetchCustomers.pending, (state) => {
      state.status = "NOT_LOADED";
    })
    .addCase(fetchCustomer.pending, (state) => {
      state.status = "LOADING_DETAILS";
    })
    .addCase(fetchCustomer.fulfilled, (state, action) => {
      state.status = "DETAILS_LOADED";
      state.logStatus = "LOG_NOT_LOADED";
      state.details = action.payload;
    })
    .addCase(editCustomer.pending, (state) => {
      state.status = "NOT_UPDATED";
    })
    .addCase(editCustomer.fulfilled, (state, action) => {
      state.status = "UPDATED";
    })
    .addCase(createCustomer.pending, (state) => {
      state.status = "NOT_CREATED";
    })
    .addCase(createCustomer.fulfilled, (state, action) => {
      state.status = "CREATED";
      state.createdCustomer = action.payload;
    })
    .addCase(createCustomer.rejected, (state, action) => {
      state.status = "REJECTED";
    })
    .addCase(deleteCustomer.pending, (state) => {
      state.status = "TO_BE_DELETED";
    })
    .addCase(deleteCustomer.fulfilled, (state) => {
        state.details = undefined;
        state.all = [];
        state.status = "DELETED";
    })
    .addCase(fetchUserLog.pending, (state) => {
        state.logStatus = "LOG_NOT_LOADED";
    })
    .addCase(fetchUserLog.fulfilled, (state, action) => {
        state.logStatus = "LOG_LOADED";
        state.AuditLog = action.payload;
    })
    .addCase(generatePassphrase.rejected, (state) => {
      state.passphraseStatus = "PASS_REJECTED"
    })
    .addCase(generatePassphrase.pending, (state) => {
        state.passphraseStatus = "PASS_NOT_CHANGED"
    })
    .addCase(generatePassphrase.fulfilled, (state, action) => {
      state.passphraseStatus = "PASS_CHANGED";
      state.passphrase = action.payload;
  })
});
