import { Snackbar } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { clearErrorMessage } from "../../store/devReducer"

export const ErrorMessage = () => {

    const errorMessage = useAppSelector(state => state.dev.errorMessage);
    const dispatch = useAppDispatch();

    const handleClose = () => {
        dispatch(clearErrorMessage());
    }

    return(
        <Snackbar open={errorMessage !== undefined}>
            <Alert onClose={handleClose} severity="error">
                {errorMessage}
            </Alert>
        </Snackbar>
    )
}
