import { Box, TextField } from "@material-ui/core";
import { Pie } from "@nivo/pie";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  StatisticsIncidentCategoryDTO,
  StatisticsIncidentCategoryDTOStatisticsResultDTO,
  StatisticsService,
} from "../../api";

interface CaseTypeStatsProps {
  customerId: string;
}

interface GraphData {
  id: string;
  value: number;
}

export const CaseTypeStats = (props: CaseTypeStatsProps) => {
  const { t } = useTranslation("default");

  const [
    stats,
    setStats,
  ] = React.useState<StatisticsIncidentCategoryDTOStatisticsResultDTO>();

  const [data, setData] = React.useState<GraphData[]>([]);
  var initalDate = new Date();
  initalDate.setFullYear(initalDate.getFullYear() - 1);
  const [startDate, setStartDate] = React.useState<Date>(initalDate);
  const [endDate, setEndDate] = React.useState<Date>(new Date());

  useEffect(() => {
    const createGraphData = (
      input: StatisticsIncidentCategoryDTO[]
    ): GraphData[] =>
      input.map((data) => ({
        id: t(`caseTypes.${data.type?.toLowerCase()}` as any),
        value: data.count!,
      }));

    if (stats?.statistics) setData(createGraphData(stats?.statistics!));
  }, [stats, t]);

  useEffect(() => {
    StatisticsService.getStatisticsAllIncidentCategories(
      props.customerId,
      startDate.toJSON(),
      endDate.toJSON()
    ).then((resp) => setStats(resp));
  }, [props.customerId, startDate, endDate]);

  const graphProps = {
    width: 1200,
    height: 500,
    margin: { top: 80, right: 120, bottom: 80, left: 120 },
    data: data,
    animate: true,
    activeOuterRadiusOffset: 8,
  };

  return (
    <Box marginTop={4}>
      <Box display="flex">
        <TextField
          id="date"
          label={t("statistics.startDate")}
          type="date"
          value={startDate.toLocaleDateString()}
          onChange={(event) =>
            setStartDate(new Date(event.target.value as string))
          }
          InputLabelProps={{
            shrink: true,
          }}
          style={{ marginRight: "2rem" }}
        />
        <TextField
          id="date"
          label={t("statistics.endDate")}
          type="date"
          value={endDate.toLocaleDateString()}
          onChange={(event) =>
            setEndDate(new Date(event.target.value as string))
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      <Pie
        {...graphProps}
        innerRadius={0.5}
        padAngle={0.5}
        cornerRadius={5}
        arcLinkLabelsColor={{
          from: "color",
        }}
        arcLinkLabelsThickness={3}
        arcLinkLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 1.2]],
        }}
      />
    </Box>
  );
};
