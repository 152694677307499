import React from "react";
import { useTranslation } from "react-i18next";
import { ConfirmButton } from "../ConfirmButton/ConfirmButton";

interface ConfirmNoConflictButtonProps {
  onConfirm: () => void;
}

export const ConfirmNoConflictButton = (
  props: ConfirmNoConflictButtonProps
) => {
  const { t } = useTranslation();

  return (
    <ConfirmButton
      color="primary"
      fullWidth={true}
      id="confirm-no-conflict"
      title={t("case.conflictOfInterest.promptTitle")}
      content={t("case.conflictOfInterest.promptContent")}
      confirmText={t("case.conflictOfInterest.promptYes")}
      cancelText={t("case.conflictOfInterest.promptNo")}
      onConfirm={props.onConfirm}
    >
      {t("case.conflictOfInterest.confirmButton")}
    </ConfirmButton>
  );
};
