import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { authReducer } from "./authReducer";
import { brandningReducer } from "./brandingReducer";
import { casesReducer } from "./casesReducer";
import { devReducer } from "./devReducer";
import { reporterReducer } from "./reporterReducer";
import { customersReducer } from "./customersReducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    cases: casesReducer,
    dev: devReducer,
    branding: brandningReducer,
    reporter: reporterReducer,
    customers: customersReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        "reporter/setAttachment",
        "reporter/reportCase/pending",
        "reporter/reportCase/fulfilled",
        "reporter/reposeCase/rejected"
      ], // Ignored so we can store File in store. Not good, not terrible.
    },
  }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
