export function formatDate(dateString?: string) {
  const date = dateFromString(dateString);
  return date.toLocaleDateString();
}

export function formatTime(dateString?: string) {
  const date = dateFromString(dateString);
  return date.toLocaleTimeString().substr(0, 5);
}

export const formatDateAndTime = (dateString?: string) =>
  `${formatDate(dateString)} ${formatTime(dateString)}`;

const dateFromString = (date?: string) =>
  new Date(`${date || "0000-00-00 00:00"}`);
