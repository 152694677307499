import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { formatDateAndTime } from "../../utils/dateUtils";
import { logTypeToString, renameReporter } from "../../utils/formUtils";
import { useTranslation } from "react-i18next";
import { AuditLogDTO } from "../../api";
import { DataGrid, GridColDef, GridRowData } from "@material-ui/data-grid";

const useStyles = makeStyles((theme) => ({
  dataGridHolder: {
    height: "50vh",
  },
}));

interface logTableProps {
  contentArray: AuditLogDTO[];
}

export const LogTable = (props: logTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: "logType",
      headerName: t("logTable.event"),
      width: 320,
      renderCell: (params: GridRowData) => {
        return <div>{logTypeToString(params.row.logType, t)}</div>;
      },
    },
    { field: "user", 
      headerName: t("logTable.user"), 
      width: 420,
      renderCell: (params: GridRowData) => {
        return <div>{renameReporter(params.row.user, t)}</div>
      }

    },
    {
      field: "timestamp",
      headerName: t("logTable.timestamp"),
      width: 220,
      renderCell: (params: GridRowData) => {
        return <div>{formatDateAndTime(params.row.timestamp)}</div>;
      },
    },
  ];

  return (
    <DataGrid
      className={classes.dataGridHolder}
      columns={columns}
      rows={props.contentArray}
      pageSize={50}
    ></DataGrid>
  );
};
