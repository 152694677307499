import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { Redirect } from "react-router-dom";

export default function OpenIdLogIn() {
  const { signinRedirect, isAuthenticated } = useAuth();

  useEffect(() => {
    signinRedirect();
  }, [])

  return (<>
    Redirecting...
    {isAuthenticated && <Redirect to="/" />}
  </>)
}