import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormControl, Input, InputLabel, InputAdornment, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from '@material-ui/icons';

interface SetPassphraseDialogProps {
  customer: string;
  open: boolean;
  onTokenSet: (passphrase: string) => void;
  onClose: () => void;
}

export const SetCustomerTokenDialog = (props: SetPassphraseDialogProps) => {
  const { open, onTokenSet, onClose, customer } = props;

  const [token, setToken] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);

  const { t } = useTranslation();

  const handleTokenSet = () => {
    onTokenSet(token!);
    setToken(undefined);
  };
  
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {t("customerTokenDialog.title", { customer })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("customerTokenDialog.description")}
          </DialogContentText>
          <FormControl fullWidth margin="dense">
            <InputLabel>{t("customerTokenDialog.token")}</InputLabel>
            <Input
              autoFocus
              margin="dense"
              id="name"
              type={showPassword ? 'text' : 'password'}
              value={token}
              onChange={(event) => setToken(event.target.value as string)}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("common.cancel")}</Button>
          <Button
            color="primary"
            onClick={handleTokenSet}
            disabled={token === undefined || token?.length === 0}
          >
            {t("customerTokenDialog.continue")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
