import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

export interface ChoiceDialogProps<ChoiceType> {
  open: boolean;
  title: string;
  onChoice: (value: ChoiceType | null) => void;
  onClose: () => void;
  getChoices: () => Promise<Choice<ChoiceType>[]>;
}

export interface Choice<T> {
  text: string;
  value: T | null;
}

export const ChoiceDialog = (props: ChoiceDialogProps<any>) => {
  const [choices, setChoices] = useState<Choice<any>[]>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (props.open === true && loading === true) {
      props.getChoices()
        .then((choices) => {
          setChoices(choices);
          setLoading(false);
        });
    }
  }, [loading, props]);

  const handleClose = () => {
    props.onClose();
  };

  const handleListItemClick = (value: string | null) => {
    props.onChoice(value);
  };

  return (
    <Dialog onClose={handleClose} open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>
      {loading ? (
        <Box padding={6} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <List>
          {choices?.map((choice) => (
            <ListItem
              button
              onClick={() => handleListItemClick(choice.value)}
              key={choice.text}
            >
              <ListItemText primary={choice.text} />
            </ListItem>
          ))}
        </List>
      )}
    </Dialog>
  );
};
