/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditLogDTO } from '../models/AuditLogDTO';
import type { CustomerDTO } from '../models/CustomerDTO';
import type { CustomerInfoDTO } from '../models/CustomerInfoDTO';
import type { NewCustomerOutputDTO } from '../models/NewCustomerOutputDTO';
import type { UserDTO } from '../models/UserDTO';
import type { UserUpdateDTO } from '../models/UserUpdateDTO';
import { request as __request } from '../core/request';

export class CustomersService {

    /**
     * Get all customers
     * @returns CustomerDTO Returns a list of customers
     * @throws ApiError
     */
    public static async getCustomers(): Promise<Array<CustomerDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/Customers`,
            errors: {
                400: `If the item is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
            },
        });
        return result.body;
    }

    /**
     * Create a new customer
     * @param requestBody 
     * @returns NewCustomerOutputDTO Customer created
     * @throws ApiError
     */
    public static async createCustomer(
requestBody?: CustomerDTO,
): Promise<NewCustomerOutputDTO> {
        const result = await __request({
            method: 'POST',
            path: `/api/Customers`,
            body: requestBody,
            errors: {
                400: `Invalid object`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
            },
        });
        return result.body;
    }

    /**
     * Get Customer by id
     * @param id 
     * @returns CustomerDTO Returns a customer found by id
     * @throws ApiError
     */
    public static async getCustomer(
id: string,
): Promise<CustomerDTO> {
        const result = await __request({
            method: 'GET',
            path: `/api/Customers/${id}`,
            errors: {
                400: `If the item is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
                404: `If customer not found`,
            },
        });
        return result.body;
    }

    /**
     * Update Customer information by id
     * @param id 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public static async updateCustomers(
id: string,
requestBody?: CustomerDTO,
): Promise<void> {
        const result = await __request({
            method: 'PUT',
            path: `/api/Customers/${id}`,
            body: requestBody,
            errors: {
                400: `If the item is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
                404: `If customer not found`,
            },
        });
        return result.body;
    }

    /**
     * Delete customer
     * @param id 
     * @returns void 
     * @throws ApiError
     */
    public static async deleteCustomer(
id: string,
): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/Customers/${id}`,
            errors: {
                400: `Invalid object`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
                404: `Customer not found`,
            },
        });
        return result.body;
    }

    /**
     * Get Customer base information by id
     * @param id 
     * @returns CustomerInfoDTO Returns a customer found by id
     * @throws ApiError
     */
    public static async getCustomerInfo(
id: string,
): Promise<CustomerInfoDTO> {
        const result = await __request({
            method: 'GET',
            path: `/api/Customers/${id}/info`,
            errors: {
                400: `If the item is null`,
                404: `If customer not found`,
            },
        });
        return result.body;
    }

    /**
     * Get all customer audit logs
     * @param id 
     * @returns AuditLogDTO Returns a auditlog  for case
     * @throws ApiError
     */
    public static async getAuditLogs(
id: string,
): Promise<Array<AuditLogDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/Customers/${id}/AuditLog`,
            errors: {
                400: `If id is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
            },
        });
        return result.body;
    }

    /**
     * Generate new passphrase
     * @param id 
     * @param customerToken 
     * @returns string Returns a new passphrase for customer
     * @throws ApiError
     */
    public static async generateNewPassphrase(
id: string,
customerToken?: string,
): Promise<Array<string>> {
        const result = await __request({
            method: 'GET',
            path: `/api/Customers/${id}/Passphrase`,
            headers: {
                'Customer-Token': customerToken,
            },
            errors: {
                400: `If id is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
            },
        });
        return result.body;
    }

    /**
     * Get all Users
     * @param id 
     * @param email Optional, filter users by email
     * @returns UserDTO Returns a list of Users
     * @throws ApiError
     */
    public static async getUsers(
id: string,
email?: string,
): Promise<Array<UserDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/Customers/${id}/Users`,
            query: {
                'email': email,
            },
            errors: {
                400: `If the item is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
                404: `No users found`,
            },
        });
        return result.body;
    }

    /**
     * Create a new User
     * @param id 
     * @param requestBody 
     * @returns UserDTO User created
     * @throws ApiError
     */
    public static async createUser(
id: string,
requestBody?: UserUpdateDTO,
): Promise<UserDTO> {
        const result = await __request({
            method: 'POST',
            path: `/api/Customers/${id}/Users`,
            body: requestBody,
            errors: {
                400: `Invalid object`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
            },
        });
        return result.body;
    }

    /**
     * Get User by id
     * @param id 
     * @param userId 
     * @returns UserDTO Returns a User found by id
     * @throws ApiError
     */
    public static async getUser(
id: string,
userId: string,
): Promise<UserDTO> {
        const result = await __request({
            method: 'GET',
            path: `/api/Customers/${id}/Users/${userId}`,
            errors: {
                400: `If the item is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
                404: `If User not found`,
            },
        });
        return result.body;
    }

    /**
     * Update User information by id
     * @param id 
     * @param userId 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public static async updateUsers(
id: string,
userId: string,
requestBody?: UserUpdateDTO,
): Promise<void> {
        const result = await __request({
            method: 'PUT',
            path: `/api/Customers/${id}/Users/${userId}`,
            body: requestBody,
            errors: {
                400: `If the item is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
                404: `If User not found`,
            },
        });
        return result.body;
    }

    /**
     * Delete User
     * @param id 
     * @param userId 
     * @returns void 
     * @throws ApiError
     */
    public static async deleteUser(
id: string,
userId: string,
): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/Customers/${id}/Users/${userId}`,
            errors: {
                400: `Invalid object`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
                404: `User not found`,
            },
        });
        return result.body;
    }

    /**
     * Get current signed in user
     * @param id 
     * @returns UserDTO Returns a User found by id
     * @throws ApiError
     */
    public static async getCurrentUser(
id: string,
): Promise<UserDTO> {
        const result = await __request({
            method: 'GET',
            path: `/api/Customers/${id}/Users/current`,
            errors: {
                400: `If the item is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
                404: `If User not found`,
            },
        });
        return result.body;
    }

}