import React, { ReactNode } from "react";
import { formatDate } from "../../utils/dateUtils";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import TodayIcon from "@material-ui/icons/Today";
import {
  AdministrativeOfficerDTO,
  CaseOutputDTO,
  CaseStatus,
  CaseViewerDTO,
  CustomersService,
  EventType,
  IncidentCategory,
  RoleType,
} from "../../api";
import { Status } from "../Cases/Status";
import { StandardDivider } from "../StandardDivider/StandardDivider";
import { Box, Button } from "@material-ui/core";
import { EditWithChoiceButton } from "../ChoiceDialog/EditWithChoiceButton";
import { Choice } from "../ChoiceDialog/ChoiceDialog";
import { HandleDownload } from "./AttachmentHandling";
import { useAppSelector } from "../../hooks/redux";
import { EditWithMultipleChoiceButton } from "../ChoiceDialog/EditWithMultipleChoiceButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    caseInformation: {},
    caseInformationTitle: {
      fontSize: 20,
      fontWeight: "bold",
      display: "flex",
      padding: theme.spacing(1, 1, 3, 1),
    },
    appendicesAndContactTitle: {
      fontSize: 20,
      fontWeight: "bold",
      display: "flex",
      padding: theme.spacing(1, 1, 1, 1),
    },
    caseDetailsTitle: {
      fontSize: 16,
      fontWeight: "bold",
      display: "flex",
      padding: theme.spacing(1, 1, 1, 1),
    },
    caseDetailsValue: {
      fontSize: 16,
      display: "flex",
      color: "#303B45",
      marginLeft: 10,
      marginBottom: 10,
    },
  })
);

export interface CaseInformationProps {
  caseDetails: CaseOutputDTO;
  editable?: boolean;
  isRoleAbleToEdit?: boolean;
  onEdit?: (changedCase: CaseOutputDTO) => void;
}

export const CaseInformation = (props: CaseInformationProps) => {
  const { caseDetails, editable } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const caseCreated = formatDate(caseDetails.caseCreated);
  const caseLastUpdated = formatDate(caseDetails.caseLatestUpdated!);
  const signedInUser = useAppSelector((state) => state.auth.user);
  const signedInCustomerId = useAppSelector((state) => state.auth.signedInCustomerId);
  const signedInUserRole = signedInUser?.customersAndRoles?.find(x => x.customerId === signedInCustomerId)?.role;

  const caseType = caseDetails.incidentCategory ?? IncidentCategory.UNSURE;

  const iconStyle: React.CSSProperties = { marginRight: 10 };

  const customerToken = useAppSelector(
    (state) => state.auth.customerCredentials?.token
  );

  const DetailsHeader = () => {
    const handleOnEditAdministrationOfficer = (
      newAdministrationOfficer: AdministrativeOfficerDTO | undefined
    ) => {
      let editedCase = {
        ...caseDetails,
      };
      editedCase.administrativeOfficer = newAdministrationOfficer;
      if (props.onEdit) props.onEdit(editedCase);
    };

    const handleOnEditCaseStatus = (newCaseStatus: CaseStatus) => {
      let editedCase = {
        ...caseDetails,
      };
      editedCase.caseStatus = newCaseStatus;
      if (props.onEdit) props.onEdit(editedCase);
    };

    const handleOnEditViewersOfficer = (caseViewers: CaseViewerDTO[] | null) => {
      const editedCase: CaseOutputDTO = {
        ...caseDetails,
        caseViewers
      }
      props.onEdit?.(editedCase);
    }

    const getAvailableAdministrationOfficers = async () => {
      let choices: Choice<AdministrativeOfficerDTO>[] = [];
      if (editable && props.isRoleAbleToEdit) {
        choices = await CustomersService.getCustomer(
          caseDetails.customerId!
        ).then((customer) => {
          return (
            customer.users?.map((user) => {
              const name = `${user.firstName} ${user.lastName}`;
              return {
                text: name,
                value: { id: user.id, name },
              } as Choice<AdministrativeOfficerDTO>;
            }) || []
          );
        });
        choices.push({
          value: null,
          text: t("case.details.noAdministrativeOfficer"),
        });
      }
      return choices;
    };

    const getAvailableCaseViewers = async () => {
      const parsedChoices: Choice<CaseViewerDTO>[] = await getAvailableAdministrationOfficers()
        .then((officers) => {
          officers.pop()
          return ([...officers]);
        });
      return parsedChoices;
    }

    const getCaseStatusChoices = async () => {
      const choices: Choice<CaseStatus>[] = Object.entries(CaseStatus).map(
        (entry) => {
          return {
            text: t(`default:caseStatus.${entry[0].toLowerCase()}` as any),
            value: entry[1],
          };
        }
      );

      return choices;
    };

    return (
      <Box>
        <EditWithChoiceButton
          getChoices={getAvailableAdministrationOfficers}
          editable={editable && props.isRoleAbleToEdit}
          onChoice={(newAdministrationOfficer: AdministrativeOfficerDTO | null) =>
            handleOnEditAdministrationOfficer(
              newAdministrationOfficer ?? undefined
            )
          }
          title={t("cases.administrativeOfficer")}
        >
          <CaseAdministrationOfficer
            administrationOfficer={caseDetails.administrativeOfficer}
          />
        </EditWithChoiceButton>
        {signedInUserRole && (
          <Box marginTop={1}>
            <EditWithMultipleChoiceButton
              defaultSelection={caseDetails.caseViewers ?? []}
              getChoices={getAvailableCaseViewers}
              editable={editable && props.isRoleAbleToEdit}
              onChoice={handleOnEditViewersOfficer}
              title={t("cases.user")}
            >
              <span>{caseDetails.caseViewers?.length ? t("case.details.viewedBy", {amount: caseDetails.caseViewers.length}) : t("case.details.viewedByNone")}</span>
            </EditWithMultipleChoiceButton>
          </Box>
        )}
        <Box paddingTop={1}>
          <Typography variant="h1">
            {t("case.case")} #{caseDetails.caseNo}
          </Typography>
        </Box>
        <Typography variant="body1">
          {t("case.details.sentIn")} - {caseCreated}
        </Typography>
        <Box marginTop={2}>
          <EditWithChoiceButton
            getChoices={getCaseStatusChoices}
            editable={editable && props.isRoleAbleToEdit}
            title={t("cases.status")}
            onChoice={(newCaseStatus) =>
              handleOnEditCaseStatus(newCaseStatus as CaseStatus)
            }
          >
            <Status status={caseDetails.caseStatus} />
          </EditWithChoiceButton>
        </Box>
      </Box>
    );
  };

  const DetailsMetadata = () => {
    const handleOnEditCaseType = (newCaseType: IncidentCategory) => {
      let editedCase = {
        ...caseDetails,
      };

      editedCase.incidentCategory = newCaseType;
      if (props.onEdit) props.onEdit(editedCase);
    };

    const getCaseTypeChoices = async () => {
      const choices: Choice<IncidentCategory>[] = Object.entries(
        IncidentCategory
      ).map((entry) => {
        return {
          text: t(`default:caseTypes.${entry[0].toLowerCase()}` as any),
          value: entry[1],
        };
      });

      return choices;
    };

    return (
      <Box>
        <Typography className={classes.caseInformationTitle} component="h2">
          {t("case.caseInformation")}
        </Typography>

        <EditWithChoiceButton
          getChoices={getCaseTypeChoices}
          editable={editable && props.isRoleAbleToEdit}
          title={t("cases.caseType")}
          onChoice={(newCaseType) =>
            handleOnEditCaseType(newCaseType as IncidentCategory)
          }
        >
          <CaseInformationSection
            title={t("cases.caseType")}
            text={t(`default:caseTypes.${caseType.toLowerCase()}` as any)}
            icon={<NewReleasesIcon style={iconStyle} />}
          ></CaseInformationSection>
        </EditWithChoiceButton>

        <CaseInformationSection
          title={t("case.lastUpdated")}
          text={caseLastUpdated}
          icon={<TodayIcon style={iconStyle} />}
        />
        <StandardDivider />
      </Box>
    );
  };

  const Attachments = () => {
    if (
      caseDetails.attachments === undefined ||
      caseDetails.attachments?.length === 0
    )
      return null;

    return (
      <Box>
        <Typography className={classes.appendicesAndContactTitle}>
          {t("case.attachments")}
        </Typography>
        {caseDetails.attachments?.map((attachment) => {
          return (
            <a
              key={attachment.id}
              href="/#"
              target="_blank"
              onClick={(event) =>
                HandleDownload(event, attachment, caseDetails, customerToken)
              }
            >
              <Typography className={classes.caseDetailsValue}>
                {attachment.filename}
              </Typography>
            </a>
          );
        })}
        <StandardDivider />
      </Box>
    );
  };

  const ContactInformation = () => {
    let contactArray: string[] = [];
    caseDetails.events?.forEach((e) => {
      if (e.eventType === EventType.CONTACT) {
        contactArray = e.content?.split("\n")!;
      }
    });

    return (
      <Box>
        <Typography className={classes.appendicesAndContactTitle} component="h2">
          {t("case.contactInformation")}
        </Typography>
        {contactArray.length > 0 ? (
          contactArray.map((e) => (
            <Typography className={classes.caseDetailsValue}>{e}</Typography>
          ))
        ) : (
          <Typography className={classes.caseDetailsValue}>
            {t("case.anonymousContact")}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <div className={classes.caseInformation}>
      <DetailsHeader />
      <StandardDivider />
      <DetailsMetadata />
      <Attachments />
      <ContactInformation />
    </div>
  );
};

interface CaseInformationSectionProps {
  title: string;
  text: string;
  icon?: ReactNode;
  children?: ReactNode;
}

const CaseInformationSection = (props: CaseInformationSectionProps) => {
  const { title, text, icon, children } = props;

  const classes = useStyles();

  if (text === null) return null;

  return (
    <div>
      <Typography className={classes.caseDetailsTitle} component="h3">
        {icon}
        {title}
      </Typography>
      <Typography className={classes.caseDetailsValue}>{text}</Typography>
      {children}
    </div>
  );
};

interface CaseAdministrationOfficerProps {
  administrationOfficer?: AdministrativeOfficerDTO | null;
}

const CaseAdministrationOfficer = (props: CaseAdministrationOfficerProps) => {
  const { administrationOfficer } = props;
  const { t } = useTranslation();

  const text = administrationOfficer
    ? t("case.details.handledBy", {
        name: administrationOfficer?.name,
      })
    : t("case.details.handleByNone");

  return <span>{text}</span>;
};

export default CaseInformation;
