import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MaterialSelect
} from "@material-ui/core";
import React from "react";
import { Option } from "../../types/Option";

export interface SelectProps {
  label: string;
  helpText?: string;
  value: string | undefined;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  options: Option[];
}


const Select = (props: SelectProps) => {
  const label = props.label;
  const helpText = props.helpText;
  const id = `${label.toLowerCase()}-select`;
  const labelId = `${id}-label`;

  return (
    <Box marginBottom={4}>
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel id={labelId}>{label}</InputLabel>
        <MaterialSelect
          required={true}
          labelId={labelId}
          id={id}
          value={props.value}
          onChange={props.onChange}
        >
          {props.options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </MaterialSelect>
        {helpText ? <FormHelperText>{helpText}</FormHelperText> : null}
      </FormControl>
    </Box>
  );
};

export default Select;
