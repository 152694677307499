import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import {
  AttachmentOutputDTO,
  AuditLogDTO,
  CaseInputDTO,
  CaseOutputDTO,
  CaseSimpleOutputDTO,
  CasesService,
  FeedbackService,
  OpenAPI,
  ApiError,
  ConflictOfInterestDTO,
  ConflictOfInterestStatus,
} from "../api";
import { RootState } from "./store";

export type CasesStatus =
  | "NOT_LOADED"
  | "LOADING_CASES"
  | "CASES_LOADED"
  | "REFRESHING_DETAILS"
  | "LOADING_DETAILS"
  | "DETAILS_LOADED";

export type LogStatus = "LOG_NOT_LOADED" | "LOG_LOADED";
export type AttachmentStatus =
  | "ATTACHMENT_PENDING"
  | "ATTACHMENT_ADDED"
  | "ATTACHMENT_REJECTED";
export type ConflictOfInterestReducerStatus =
  | "FETCHING_CONFLICT"
  | "CONFLICT_FETCHED"
  | "SETTING_CONFLICT"
  | "CONFLICT_SET";

interface CasesState {
  status: CasesStatus;
  logStatus: LogStatus;
  attachmentStatus?: AttachmentStatus;
  conflictOfInterestStatus?: ConflictOfInterestReducerStatus;
  all: CaseSimpleOutputDTO[];
  details?: CaseOutputDTO;
  auditLog: AuditLogDTO[];
  conflictOfInterest?: ConflictOfInterestDTO;
}

export const fetchCases = createAsyncThunk("cases/fetchAll", async () => {
  return await CasesService.getCases();
});

export const fetchFeedback = createAsyncThunk("feedback/fetchAll", async () => {
  return await FeedbackService.getFeedback();
});

export const fetchCase = createAsyncThunk(
  "cases/fetchFromId",
  async (id: string, thunkAPI) => {
    const customerToken = (thunkAPI.getState() as RootState).auth
      .customerCredentials?.token;
    return await CasesService.getCase(id, customerToken ?? "");
  }
);

export const addMessageToCase = createAsyncThunk(
  "cases/addMessage",
  async ({ id, message }: { id: string; message: string }, thunkAPI) => {
    const customerToken = (thunkAPI.getState() as RootState).auth
      .customerCredentials?.token;
    return await CasesService.postMessage(id, customerToken ?? "", {
      content: message,
    });
  }
);

export const deleteCase = createAsyncThunk(
  "cases/deleteCase",
  async (id: string) => {
    return await CasesService.deleteCase(id);
  }
);

export const updateCase = createAsyncThunk(
  "cases/updateCase",
  async (updatedCase: CaseInputDTO, thunkAPI) => {
    const customerToken = (thunkAPI.getState() as RootState).auth
      .customerCredentials?.token;
    return await CasesService.putCase(
      updatedCase.id!,
      customerToken ?? "",
      updatedCase
    );
  }
);

export const fetchCaseLog = createAsyncThunk(
  "cases/fetchCaseLog",
  async (caseId: string) => {
    return await CasesService.getAuditLogs(caseId);
  }
);

export const fetchConflictOfInterest = createAsyncThunk(
  "cases/fetchConflicOfInterest",
  async (id: string) => {
    return await CasesService.getConflictOfInterest(id);
  }
);

export const setConflictOfInterest = createAsyncThunk(
  "cases/setConflictOfInterest",
  async (
    { id, body }: { id: string; body: ConflictOfInterestStatus },
    thunkAPI
  ) => {
    return await CasesService.setConflictOfInterest(id, { status: body });
  }
);

export const addAttachmentToCase = createAsyncThunk(
  "cases/addAttachment",
  async ({ id, file }: { id: string; file: any }, thunkAPI) => {
    let auth: string;
    if (OpenAPI.TOKEN === undefined)
      auth = "Basic " + btoa(OpenAPI.USERNAME + ":" + OpenAPI.PASSWORD);
    else auth = `Bearer ${OpenAPI.TOKEN}`;

    const url = OpenAPI.BASE + `/api/Cases/${id}/Attachments`;

    try {
      const response = await fetch(url, {
        headers: {
          Authorization: auth,
          "SignedInCustomer": `${OpenAPI.SIGNEDINCUSTOMERID}`
        },
        method: "POST",
        body: file,
      });

      const data = await response.json();
      if (response.ok) {
        return data as AttachmentOutputDTO[];
      } else {
        thunkAPI.dispatch({
          type: "dev/setErrorMessage",
          payload: JSON.stringify(data),
        });
        throw new ApiError(response, "Adding attachment failed");
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const initialState = {
  all: [],
  logStatus: "LOG_NOT_LOADED",
  status: "NOT_LOADED",
  auditLog: [],
} as CasesState;

export const casesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addMessageToCase.pending, (state) => {
      state.status = "REFRESHING_DETAILS";
    })
    .addCase(addMessageToCase.fulfilled, (state, action) => {
      state.details?.events?.push(action.payload);
      state.status = "DETAILS_LOADED";
    })
    .addCase(fetchCases.fulfilled, (state, action) => {
      state.status = "CASES_LOADED";
      state.all = action.payload;
    })
    .addCase(fetchCases.pending, (state) => {
      state.status = "LOADING_CASES";
    })
    .addCase(fetchFeedback.fulfilled, (state, action) => {
      state.status = "CASES_LOADED";
      state.all = action.payload;
    })
    .addCase(fetchFeedback.pending, (state) => {
      state.status = "LOADING_CASES";
    })
    .addCase(deleteCase.pending, (state) => {
      state.status = "REFRESHING_DETAILS";
    })
    .addCase(deleteCase.fulfilled, (state) => {
      state.details = undefined;
      state.all = [];
      state.status = "NOT_LOADED";
    })
    .addCase(fetchCase.rejected, (state) => {
      state.status = "NOT_LOADED";
    })
    .addCase(fetchCase.pending, (state) => {
      state.status = "LOADING_DETAILS";
    })
    .addCase(updateCase.pending, (state) => {
      state.status = "REFRESHING_DETAILS";
    })
    .addCase(updateCase.fulfilled, (state, action) => {
      state.status = "DETAILS_LOADED";
      state.details = action.payload;
    })
    .addCase(fetchCase.fulfilled, (state, action) => {
      state.status = "DETAILS_LOADED";
      state.logStatus = "LOG_NOT_LOADED";
      state.details = action.payload;
    })
    .addCase(fetchCaseLog.pending, (state) => {
      state.logStatus = "LOG_NOT_LOADED";
    })
    .addCase(fetchCaseLog.fulfilled, (state, action) => {
      state.logStatus = "LOG_LOADED";
      state.auditLog = action.payload;
    })
    .addCase(addAttachmentToCase.pending, (state) => {
      state.attachmentStatus = "ATTACHMENT_PENDING";
      state.status = "REFRESHING_DETAILS";
    })
    .addCase(addAttachmentToCase.fulfilled, (state, action) => {
      state.details?.attachments!.push(action.payload[0]);
      state.attachmentStatus = "ATTACHMENT_ADDED";
      state.status = "DETAILS_LOADED";
    })
    .addCase(addAttachmentToCase.rejected, (state) => {
      state.attachmentStatus = "ATTACHMENT_REJECTED";
      state.status = "DETAILS_LOADED";
    })
    .addCase(fetchConflictOfInterest.pending, (state) => {
      state.conflictOfInterestStatus = "FETCHING_CONFLICT";
      state.status = "LOADING_DETAILS";
    })
    .addCase(fetchConflictOfInterest.fulfilled, (state, action) => {
      state.conflictOfInterestStatus = "CONFLICT_FETCHED";
      state.conflictOfInterest = action.payload;
    })
    .addCase(setConflictOfInterest.pending, (state) => {
      state.conflictOfInterestStatus = "SETTING_CONFLICT";
      state.status = "LOADING_DETAILS";
    })
    .addCase(setConflictOfInterest.fulfilled, (state, action) => {
      state.conflictOfInterestStatus = "CONFLICT_SET";
      state.conflictOfInterest = action.payload;
    });
});
