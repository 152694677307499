import React, { useCallback, useEffect, useRef, useState } from "react";
import { CaseDetails } from "./CaseDetails";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  deleteCase,
  fetchCase,
  fetchConflictOfInterest,
  setConflictOfInterest,
  updateCase,
} from "../../store/casesReducer";
import { Loading } from "../Loading/Loading";
import { DeleteCaseButton } from "./DeleteCaseButton";
import { StandardDivider } from "../StandardDivider/StandardDivider";
import { useHistory, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  CaseOutputDTO,
  ConflictOfInterestDTO,
  ConflictOfInterestStatus,
  RoleType,
} from "../../api";
import { Box, Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { caseOutputDTOToInputDTO } from "../../utils/caseUtils";
import { setErrorMessage } from "../../store/devReducer";
import { resetCustomerToken } from "../../store/authReducer";
import { ErrorMessage } from "../ErrorHandler/ErrorMessage";
import { ConfirmNoConflictButton } from "./ConfirmNoConflictButton";
import { HandleDownload } from "./AttachmentHandling";

interface ParamTypes {
  id: string;
}

export const CaseContainer = () => {
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const customerToken = useAppSelector((state) => state.auth.customerCredentials?.token);

  const { id } = useParams<ParamTypes>();
  const dispatch = useAppDispatch();
  const caseStatus = useAppSelector((state) => state.cases.status);
  const attachmentStatus = useAppSelector(
    (state) => state.cases.attachmentStatus
  );

  const caseDetails = useAppSelector((state) => state.cases.details);
  const [caseWorkingCopy, setCaseWorkingCopy] = useState<CaseOutputDTO>(
    caseDetails!
  );

  const signedInUser = useAppSelector((state) => state.auth.user);
  const signedInCustomerId = useAppSelector((state) => state.auth.signedInCustomerId);
  const signedInUserRole = signedInUser?.customersAndRoles?.find(x => x.customerId === signedInCustomerId)?.role;

  const isRoleAbleToEdit = signedInUserRole === RoleType.ADMIN || signedInUserRole === RoleType.GLOBAL_ADMIN || caseWorkingCopy?.administrativeOfficer?.id === signedInUser?.id
  const isViewer = caseDetails?.caseViewers?.some((user) => user.id === signedInUser?.id) && isRoleAbleToEdit === false;

  const conflict = useAppSelector(
    (state) => state.cases.conflictOfInterest
  ) as ConflictOfInterestDTO;

  const [edited, setEdited] = useState<boolean>(false);

  const conflictStatus =
    conflict?.status! !== ConflictOfInterestStatus.NOT_CONFLICT_OF_INTEREST;

  const history = useHistory();
  const { t } = useTranslation();

  const dispatchFetchCase = useCallback(() => {
    dispatch(fetchCase(id))
    .then(unwrapResult)
    .catch((error) => {
      // On error, show message, reset customer token and go back to /cases
      dispatch(setErrorMessage(error.message));
      dispatch(resetCustomerToken());
      history.goBack();
    });
  }, [dispatch, history, id]);

  // Fetch case if id in url changes
  useEffect(() => {
    dispatch(fetchConflictOfInterest(id)).then(() => dispatchFetchCase());
  }, [id, dispatch, dispatchFetchCase]);

  // Set new working copy if original changes
  useEffect(() => {
    if (caseDetails) setCaseWorkingCopy(caseDetails);
  }, [caseDetails]);

  // Set edited flag if working copy differs from original
  useEffect(() => {
    if (JSON.stringify(caseWorkingCopy) !== JSON.stringify(caseDetails)) {
      setEdited(true);
    } else {
      setEdited(false);
    }
  }, [caseWorkingCopy, caseDetails]);

  const handleDeleteCase = () => {
    dispatch(deleteCase(id))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        history.push("/cases");
      });
  };

  const shouldShowDetail = () => {
    switch (caseStatus) {
      case "DETAILS_LOADED":
      case "REFRESHING_DETAILS":
        return true;

      default:
        return false;
    }
  };

  const shouldShowLoading = () => {
    switch (caseStatus) {
      case "REFRESHING_DETAILS":
      case "LOADING_DETAILS":
        return true;

      default:
        return false;
    }
  };

  const shouldShowError = () => {
    if (attachmentStatus === "ATTACHMENT_REJECTED") return true;
    return false;
  };

  const onExportCaseToFile = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if(!downloadLinkRef.current) return;
    downloadLinkRef.current.href = `data:text/html;charset=UTF-8,${encodeURIComponent(document.documentElement.outerHTML)}`;
    downloadLinkRef.current.download = `exported-${caseWorkingCopy.id ?? 'no-id'}.html`
    for(const attachment of caseWorkingCopy.attachments ?? []) {
      HandleDownload(event, attachment, caseWorkingCopy, customerToken)
    }
    downloadLinkRef.current.click();
  }

  const onAddedAttachment = () => {
    dispatchFetchCase();
  };

  const onEditWorkingCopy = (changedCase: CaseOutputDTO) => {
    setCaseWorkingCopy(changedCase);
  };

  const onSave = () => {
    dispatch(updateCase(caseOutputDTOToInputDTO(caseWorkingCopy)));
  };

  const handleNoConflicOfInterest = () => {
    dispatch(
      setConflictOfInterest({
        id: id,
        body: ConflictOfInterestStatus.NOT_CONFLICT_OF_INTEREST,
      })
    )
      .then(() => dispatch(fetchConflictOfInterest(id)))
      .finally(() => dispatch(fetchCase(id)));
  };

  return (
    <>
      {shouldShowDetail() ? (
        <CaseDetails
          isRoleAbleToEdit={isRoleAbleToEdit}
          isViewer={isViewer}
          editable={!conflictStatus}
          isReporter={false}
          caseDetails={caseWorkingCopy}
          onAddedAttachment={() => onAddedAttachment()}
          onEdit={(changedCase) => onEditWorkingCopy(changedCase)}
        >
          <StandardDivider />
          <Box marginTop={1}>
            <Button
              fullWidth={true}
              variant="outlined"
              onClick={onExportCaseToFile}
            >
              {t("case.details.download")}
            </Button>
            <a ref={downloadLinkRef}></a>
          </Box>
          {isViewer !== true && (<>
            {!conflictStatus ? (
              <div>
                <StandardDivider />
                <Button
                  onClick={onSave}
                  fullWidth={true}
                  disabled={!edited}
                  color="primary"
                  variant="contained"
                >
                  {t("case.details.saveCase")}
                </Button>
                <Box marginTop={1}>
                  <Button
                    fullWidth={true}
                    variant="outlined"
                    onClick={() => history.push("/cases")}
                  >
                    {t("common.cancel")}
                  </Button>
                </Box>
                  <StandardDivider />
                  <DeleteCaseButton onDelete={handleDeleteCase} />
              </div>
            ) : (
              <div>
                <StandardDivider />
                <Typography>{t("case.conflictOfInterest.info")}</Typography>
                <ConfirmNoConflictButton onConfirm={handleNoConflicOfInterest} />
                <Box marginTop={1}>
                  <Button
                    fullWidth={true}
                    variant="outlined"
                    onClick={() => history.push("/cases")}
                  >
                    {t("common.cancel")}
                  </Button>
                </Box>
              </div>
          )}
          </>)}
          {shouldShowError() ? <ErrorMessage /> : null}
        </CaseDetails>
      ) : null}
      {shouldShowLoading() ? <Loading /> : null}
    </>
  );
};
