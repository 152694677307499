import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { CustomerInfoDTO, CustomersService, Language } from "../api";
import { RootState } from "./store";

// TODO: Replace with API models when we have them
export type CustomerBrandingStatus =
  | "LOADED"
  | "SET_NOT_LOADED"
  | "NOT_LOADED"
  | "NOT_FOUND"
  | "LOADING_BRANDING";
interface BrandingState {
  branding?: CustomerInfoDTO;
  customerId: string;
  status: CustomerBrandingStatus;
}

export const setCustomerId = createAction<string>("branding/setCustomerId");

export const setDefaultLanguage = createAction<Language>("branding/setDefaultLanguage");

export const fetchCustomerBrandning = createAsyncThunk(
  "branding/fetchById",
  async (_, thunkAPI) => {
    const customerId = (thunkAPI.getState() as RootState).branding.customerId;
    return await CustomersService.getCustomerInfo(customerId);
  }
);

const initialState = {
  status: "NOT_LOADED",

  branding: {
    image: "https://i.imgur.com/QWwYC5A.png",
    name: "Human & Heart",
    showFeedbackcase: true,
    backgroundColor: "#FFEDE3",
    defaultLanguage: undefined
  },
} as BrandingState;

export const brandningReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCustomerId, (state, action) => {
      state.customerId = action.payload;
      state.branding = initialState.branding;
      state.status = "SET_NOT_LOADED";
    })
    .addCase(setDefaultLanguage, (state, action) => {
      state.branding!.defaultLanguage = action.payload;
    })
    .addCase(fetchCustomerBrandning.pending, (state, action) => {
      state.status = "LOADING_BRANDING";
    })
    .addCase(fetchCustomerBrandning.rejected, (state, action) => {
      state.status = "NOT_FOUND";
    })
    .addCase(fetchCustomerBrandning.fulfilled, (state, action) => {
      state.status = "LOADED";
      state.branding = action.payload;
    });
});
