import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

export interface ConfirmationDialogProps {
  id: string;
  open: boolean;
  onCancel: () => void;
  onConfirm: (e : any) => void;
  title: string;
  content: string;
  confirmText: string;
  cancelText: string;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      aria-labelledby="confirmation-dialog-title"
      open={props.open}
    >
      <DialogTitle id="confirmation-dialog-title">{props.title}</DialogTitle>
      <DialogContent dividers>{props.content}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.onCancel}>
          {props.cancelText}
        </Button>
        <Button onClick={props.onConfirm} color="primary" type="submit">
          {props.confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
