import React from "react";
import {
  DataGrid,
  GridColDef,
  GridRowData,
  GridValueFormatterParams,
} from "@material-ui/data-grid";
import { formatDate } from "../../utils/dateUtils";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import { CaseSimpleOutputDTO, CaseStatus, IncidentCategory } from "../../api";
import { Status } from "./Status";
import { useAppSelector } from "../../hooks/redux";

interface CasesTableProps {
  cases: CaseSimpleOutputDTO[];
  onHandleCase: (caseToHandle: CaseSimpleOutputDTO) => void;
}

const useStyles = makeStyles((theme) => ({
  dataGridHolder: {
    height: "80vh",
    width: "100%",
  },
  shape: {
    width: 10,
    height: 10,
  },
  shapeCircle: {
    display: "inline-block",
    borderRadius: "50%",
  },
  buttonIcon: {
    marginRight: theme.spacing(0.5),
    height: "20px",
  },
}));

export const CasesTable = (props: CasesTableProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  function formatIncidentCateogry(category: IncidentCategory) {
    return t(`default:caseTypes.${category.toLowerCase()}` as any);
  }

  const customerCredentials = useAppSelector(
    (state) => state.auth.customerCredentials
  );

  const columns: GridColDef[] = [
    {
      field: "customerName",
      headerName: t("cases.customer"),
      width: 180,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value?.toString().replace("#", ""),
    },
    { field: "caseNo", headerName: t("cases.caseNumber"), width: 180 },
    {
      field: "incidentCategory",
      headerName: t("cases.caseType"),
      width: 540,
      valueFormatter: (params: GridValueFormatterParams) =>
        formatIncidentCateogry(params.value as IncidentCategory),
    },
    {
      field: "caseCreated",
      headerName: t("cases.received"),
      width: 180,
      valueFormatter: (params: GridValueFormatterParams) =>
        formatDate(params.value as string),
    },
    {
      field: "caseStatus",
      headerName: t("cases.status"),
      width: 200,
      renderCell: (params: GridRowData) => (
        <Status status={params.row.caseStatus as CaseStatus} />
      ),
    },
    { field: "answers", headerName: t("cases.replies"), width: 100 },
    {
      field: "administrativeOfficer",
      headerName: t("cases.administrativeOfficer"),
      width: 200,
      renderCell: (params: GridRowData) =>
        params.row.administrativeOfficer?.name
    },
    {
      field: "handleCaseButton",
      headerName: " ",
      width: 180,
      renderCell: (params: GridRowData) => (
        <Button
          variant="outlined"
          size="small"
          style={{ marginLeft: 16 }}
          onClick={() => props.onHandleCase(params.row as CaseSimpleOutputDTO)}
        >
          <>
            {(params.row as CaseSimpleOutputDTO).customerName !==
            customerCredentials?.customer ? (
              <LockIcon className={classes.buttonIcon} />
            ) : (
              <LockOpenIcon className={classes.buttonIcon} />
            )}
            {t("cases.manage")}
          </>
        </Button>
      ),
    },
  ];

  return (
    <div className={classes.dataGridHolder}>
      <DataGrid rows={props.cases} columns={columns} pageSize={50} />
    </div>
  );
};
