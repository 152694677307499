import { Box, Grid, makeStyles, Paper } from "@material-ui/core";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { CaseOutputDTO } from "../../api";
import { useAppDispatch } from "../../hooks/redux";
import {
  addMessageToCase,
  addAttachmentToCase,
} from "../../store/casesReducer";
import { CaseEvents } from "./CaseEvents";
import CaseInformation from "./CaseInformation";
import { CaseLog } from "./CaseLog";
import { TabHandler } from "../TabHandler/TabHandler";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: "10px",
  },
}));

interface CaseDetailsProps {
  children?: ReactNode;
  editable?: boolean;
  isReporter?: boolean;
  isViewer?: boolean;
  isRoleAbleToEdit?: boolean;
  caseDetails?: CaseOutputDTO;
  onAddedAttachment?: () => void;
  onEdit?: (changedCase: CaseOutputDTO) => void;
}

export const CaseDetails = (props: CaseDetailsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const caseDetails = props.caseDetails;

  const handleSendMessage = (message?: string, attachment?: File) => {

    if (message !== undefined) {
      dispatch(addMessageToCase({ id: caseDetails?.id!, message }));
    }
    if (attachment !== undefined) {
      const attachmentForm = new FormData();
      attachmentForm.append("files", attachment);
      dispatch(
        addAttachmentToCase({ id: caseDetails?.id!, file: attachmentForm })
      ).then(() => {
        if (props.onAddedAttachment) props.onAddedAttachment();
      });
    }
  };

  return (
    <Paper elevation={0} className={classes.paper}>
      <Grid container>
        <Grid item lg={3} md={3}>
          <Box padding={4} textAlign="left">
            {caseDetails ? (
              <CaseInformation
                isRoleAbleToEdit={props.isRoleAbleToEdit}
                editable={props.editable}
                caseDetails={caseDetails}
                onEdit={props.onEdit}
              />
            ) : null}
            {props.children}
          </Box>
        </Grid>
        <Grid item lg={9} md={9} sm={12}>
          {props.editable || props.isViewer ? (
            <Box padding={0.5}>
              <TabHandler
                tabHeaderOne={t("case.messages")}
                tabHeaderTwo={t("case.log")}
                firstTabContent={
                  <CaseEvents
                    hideMessageSubmit={props.isViewer}
                    events={caseDetails?.events ?? []}
                    editable={props.editable}
                    isReporter={props.isReporter}
                    onSendMessage={(message, attachment) =>
                      handleSendMessage(message, attachment)
                    }
                  />
                }
                secondTabContent={
                  caseDetails?.id! ? <CaseLog caseId={caseDetails.id} /> : null
                }
              />
            </Box>
          ) : (
            <Box padding={0.5}>
              <CaseEvents
                events={caseDetails?.events ?? []}
                editable={props.editable}
                isReporter={props.isReporter}
                onSendMessage={(message, attachment) =>
                  handleSendMessage(message, attachment)
                }
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};
