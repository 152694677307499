import { Box, Typography } from "@material-ui/core";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CaseTypeStats } from "./CaseTypeStats";

type AvailableStatsType = "casetype";

interface StatisticsURL {
  customerId: string;
  statsType: AvailableStatsType;
}

export const Statistics = () => {
  const { customerId, statsType } = useParams() as StatisticsURL;

  const { t } = useTranslation("default");

  return (
    <Box textAlign="left">
      <Typography variant="h1">{t("menu.statistics")}</Typography>
      {getStatsComponent(statsType, customerId)}
    </Box>
  );
};
function getStatsComponent(
  statsType: AvailableStatsType,
  customerId: string
): ReactNode {
  switch (statsType) {
    case "casetype":
      return <CaseTypeStats customerId={customerId} />;
    default:
      return null;
  }
}
