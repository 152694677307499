import { Box, Button, Menu, MenuItem } from "@material-ui/core"
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import  { ReactComponent as FlagSE } from "../Icons/Flags/SE.svg";
import  { ReactComponent as FlagDE } from "../Icons/Flags/DE.svg";
import  { ReactComponent as FlagEU } from "../Icons/Flags/EU.svg";
import  { ReactComponent as FlagDK } from "../Icons/Flags/DK.svg";
import  { ReactComponent as FlagNO } from "../Icons/Flags/NO.svg";
import  { ReactComponent as FlagFI } from "../Icons/Flags/FI.svg";
import  { ReactComponent as FlagPL } from "../Icons/Flags/PL.svg";
import  { ReactComponent as FlagES } from "../Icons/Flags/ES.svg";
import  { ReactComponent as FlagUnknown } from "../Icons/Flags/WW.svg";
import { Language } from "../../api";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { setDefaultLanguage } from "../../store/brandingReducer";

export interface LanguageSwitcherProps {
    isSettingDefaultValue: boolean;
    onChange?: (languageCode: AvailableLanguages) => any
}

export const LanguageSwitcher = (props: LanguageSwitcherProps) => {
    const { i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const [language, setLanguage] = React.useState<string>(i18n.language);

    const defaultLanguage = useAppSelector((state) => state.branding.branding?.defaultLanguage);
    let isDefaultLanugageLoaded = useRef(false);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleLanguageSelectorClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const changeLanguage = (languageCode: AvailableLanguages) => {
        if(props.isSettingDefaultValue && props.onChange !== undefined){
            let languageType = getLanguageType(languageCode);
            dispatch(setDefaultLanguage(languageType as Language));
            props.onChange(languageCode);
        }
        setLanguage(languageCode);
        handleClose();
    };

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language, i18n]);

    useEffect(() => {
        if(!isDefaultLanugageLoaded.current && defaultLanguage !== undefined){
          let defaultLanguageCode = getLanguageCode(defaultLanguage);
          i18n.changeLanguage(defaultLanguageCode);
          setLanguage(defaultLanguageCode as AvailableLanguages);
          isDefaultLanugageLoaded.current = true;
        }
      }, [i18n, defaultLanguage]);

    return (
        <div>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleLanguageSelectorClick}>
        {getFlagIcon(language as AvailableLanguages)}<Box marginLeft={1}>{language.toUpperCase()}</Box>
        </Button>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <LanguageOption languageCode="sv" onClick={changeLanguage} />
            <LanguageOption languageCode="en" onClick={changeLanguage} />
            <LanguageOption languageCode="dk" onClick={changeLanguage} />
            <LanguageOption languageCode="de" onClick={changeLanguage} />
            <LanguageOption languageCode="es" onClick={changeLanguage} />
            <LanguageOption languageCode="no" onClick={changeLanguage} />
            <LanguageOption languageCode="pl" onClick={changeLanguage} />
            <LanguageOption languageCode="fi" onClick={changeLanguage} />
        </Menu>
      </div>
    )
}

export type AvailableLanguages = "sv" | "en" | "dk" | "de" | "es" | "no" | "pl" | "fi" ;

export interface LanguageOptionProps {
    languageCode: AvailableLanguages;
    onClick: (languageCode: AvailableLanguages) => any
}

export const getFlagIcon = (languageCode: AvailableLanguages) => {
    switch(languageCode) {
        case "en": return <FlagEU />;
        case "dk": return <FlagDK />;
        case "fi": return <FlagFI />;
        case "no": return <FlagNO />;
        case "de": return <FlagDE />;
        case "sv": return <FlagSE />;
        case "pl": return <FlagPL />;
        case "es": return <FlagES />;
        default: return <FlagUnknown />;
    }
}

export const getLanguageString = (languageCode: AvailableLanguages) => {
    switch(languageCode) {
        case "en": return "English";
        case "dk": return "Dansk";
        case "fi": return "Suomalainen";
        case "no": return "Norsk";
        case "de": return "Deutsch";
        case "sv": return "Svenska";
        case "pl": return "Polski";
        case "es": return "Español";
        default: return "Unknown language";
    }
}

export const getLanguageType = (languageCode: AvailableLanguages) => {
    switch(languageCode) {
        case "en": return Language.ENGLISH;
        case "dk": return Language.DANISH;
        case "fi": return Language.FINNISH;
        case "no": return Language.NORWEGIAN;
        case "de": return Language.GERMAN;
        case "sv": return Language.SWEDISH;
        case "pl": return Language.POLISH;
        case "es": return Language.SPANISH;
        default: return undefined;
    }
}

export const getLanguageCode = (languageType: Language) => {
    switch(languageType) {
        case Language.ENGLISH: return "en";
        case Language.DANISH: return "dk";
        case Language.FINNISH: return "fi";
        case Language.NORWEGIAN: return "no";
        case Language.GERMAN: return "de";
        case Language.SWEDISH: return "sv";
        case Language.POLISH: return "pl";
        case Language.SPANISH: return "es";
        default: return undefined;
    }
}


 export const LanguageOption = (props: LanguageOptionProps) => {

    return(
        <MenuItem value={props.languageCode} onClick={() => props.onClick(props.languageCode)}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {getFlagIcon(props.languageCode)}
                <Box marginLeft={1}>{getLanguageString(props.languageCode)}</Box>
            </div>
        </MenuItem>
    )
}