import { createMuiTheme } from "@material-ui/core/styles";

// Override Material UI theme here!
function createAppTheme(useDarkMode: boolean) {
  const standardHeaderColor = useDarkMode ? "#EFEFEF" : "#27272E";
  const standardBodyColor = useDarkMode ? "#dcdcdc" : "#425466";

  return createMuiTheme({
    props: {
      MuiButtonBase: {
        disableRipple: false,
      },
      MuiButton: {
        disableRipple: true
      }
    },
    overrides: {
      MuiFormControl: {
        root: {
          '& label': {
            color: '#27272E',
          },
          '& label.Mui-focused': {
            color: '#16192C',
            '& > .Mui-focusVisible': {
              borderColor: '#16192C',
              borderWidth: '2px'
            },
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#16192C',
              borderWidth: '2px'
            },
          },

          '& > .Mui-focusVisible': {
              borderColor: '#16192C',
              borderWidth: '2px'

          },
        }
      },
      MuiLink: {
        root:{
          "&:focus": {
            outline: '2px solid #16192C',
          },
        }
      },
      MuiButtonBase: {
        root:{
          "&:focus": {
            border: '2px solid #16192C',
          },
          "&.MuiButton-containedSecondary:focus": {
            "outline": "2px solid #16192C",
            "border": "2px solid white"
          },
        }
      },
      MuiOutlinedInput: {
        root: {
          '& label.Mui-focused': {
            color: '#16192C',
          },
          "&$focused .MuiSelect-root": {
            borderColor: '#16192C',
            borderWidth: '2px'
          },
        }
      },
      MuiMenuItem: {
        root: {
          "&$selected": {
            backgroundColor: "white",
            color: '#16192C',
          },
        }
      }
    },
    typography: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
      h1: {
        fontWeight: 300,
        fontSize: 36,
        color: standardHeaderColor,
      },
      h2: {
        fontWeight: 300,
        fontSize: 28,
        color: standardHeaderColor,
      },
      h3: {
        fontWeight: 300,
        fontSize: 20,
        color: standardHeaderColor,
      },
      h4: {
        fontWeight: 500,
        fontSize: 18,
        color: standardHeaderColor,
      },
      h5: {
        fontWeight: 500,
        fontSize: 16,
        color: standardHeaderColor,
      },
      h6: {
        fontWeight: 500,
        fontSize: 14,
        color: standardHeaderColor,
      },
      body1: {
        fontWeight: 400,
        fontSize: 16,
        color: standardBodyColor,
      },
      body2: {
        fontWeight: 500,
        fontSize: 16,
        color: standardBodyColor,
      },
    },
    palette: {
      type: useDarkMode ? "dark" : "light",
      primary: {
        main: "#FF4C77",
      },
      secondary: {
        main: "#16192C",
      },
      action: {
        selected: "#fce6e7",
      },
      error: {
        main: "#E90C40"
      }
    },
    custom: {
      textBodyLight: "#425466",
    },
  });
}

export const lightTheme = createAppTheme(false);
export const darkTheme = createAppTheme(true);
