import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Choice, ChoiceDialogProps } from "./ChoiceDialog";

interface ChoiceMultipleDialogProps<Type> extends Omit<ChoiceDialogProps<Type>, "onChoice">{
  onChoice: (value: Type[] | null) => void;
  defaultSelection?: Type[];
}

export function ChoiceMultipleDialog<T> (props: ChoiceMultipleDialogProps<T>) {
  const [choices, setChoices] = useState<Choice<T>[]>();
  const [selectedChoices, setSelectedChoices] = useState<T[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const { t } = useTranslation()

  useEffect(() => {
    if(props.defaultSelection && !selectedChoices) setSelectedChoices(props.defaultSelection)
  }, [props.defaultSelection])

  useEffect(() => {
    if (props.open !== true || loading !== true) return;
    props.getChoices()
      .then(setChoices)
      .finally(() => setLoading(false))
  }, [props.open]);

  const handleCancel = () => {
    props.onClose()
  }

  const handleClose = () => {
    props.onChoice(selectedChoices ?? []);
    props.onClose();
  };

  const handleListItemClick = (value: T | null) => {
    if(!value) return;
    const foundIndex = selectedChoices?.findIndex((item) => JSON.stringify(item) === JSON.stringify(value)) ?? -1
    if(foundIndex >= 0) setSelectedChoices((state) => {
      let newState = [...state ?? []];
      newState.splice(foundIndex, 1);
      return newState;
    })
    else setSelectedChoices((state) => {
      const newState = [...state ?? [], value];
      return newState;
    })
  };

  return (
    <Dialog onClose={handleClose} open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
      {loading ? (
        <Box padding={6} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (<>
        <List>
          {choices?.map((choice) => (
            <ListItem
              key={choice.text}
            >
              <FormControlLabel control={
                <Checkbox
                  key={`${choice.text}-checkbox`}
                  onClick={() => handleListItemClick(choice.value)}
                  checked={selectedChoices?.some((item) => JSON.stringify(item) === JSON.stringify(choice.value))}
                  defaultChecked
                />
              } label={choice.text} />
            </ListItem>
          ))}
        </List>
      </>)}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{t("common.cancel")}</Button>
        <Button onClick={handleClose}>{t("common.done")}</Button>
      </DialogActions>
    </Dialog>
  );
};
