/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EventType {
    MESSAGE = 'Message',
    STATUS_UPDATE = 'StatusUpdate',
    ADMINISTRATIVE_OFFICER_ASSIGNED = 'AdministrativeOfficerAssigned',
    ATTACHMENT_ADDED = 'AttachmentAdded',
    CONTACT = 'Contact',
    NOT_CONFLICT_OF_INTEREST = 'NotConflictOfInterest',
    CONFLICT_OF_INTEREST = 'ConflictOfInterest',
    INCIDENT_CATEGORY_UPDATE = 'IncidentCategoryUpdate',
    CASE_VIEWER_ASSIGNED = 'CaseViewerAssigned',
    CASE_VIEWER_UN_ASSIGNED = 'CaseViewerUnAssigned',
}