import React, { ReactNode, useEffect, useRef } from "react";
import { formatDateAndTime } from "../../utils/dateUtils";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { EventOutputDTO, EventType } from "../../api";
import { useTranslation } from "react-i18next";
import { renameReporter } from "../../utils/formUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageBox: {
      padding: theme.spacing(0, 2, 0, 0),
      marginTop: 5,
      marginBottom: 5,
    },
    senderText: {
      fontWeight: "bold",
      marginRight: 10,
      fontSize: 16,
    },
    timeText: {
      fontSize: 12,
      color: "gray",
    },
    messageText: {
      color: "#303B45",
    },
  })
);

interface EventListProps {
  events: EventOutputDTO[];
}

export interface Message {
  caseId: number;
  id: number;
  sender: string;
  timeStamp: Date;
  message: string;
  avatar: string;
}

export const EventList = (props: EventListProps) => {
  const { events } = props;

  const eventsEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    eventsEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [events]);

  if (events.length > 0) {
    return (
      <div>
        {events.map((event, index) => {
          return createEventEntry(event, index);
        })}
        <div ref={eventsEndRef} />
      </div>
    );
  }
  return <Typography>No messages</Typography>;
};

const UnknownEventEntry = (props: EventOutputDTO) => {
  return (
    <CommonEvent>
      Unknown event of type: {props.eventType} and content: {props.content}{" "}
    </CommonEvent>
  );
};

const StatusEventEntry = (props: EventOutputDTO) => {
  const { t } = useTranslation();

  const statusText = t(`caseStatus.${props.content?.toLowerCase()}` as any);

  return (
    <CommonEvent>
      {t("case.events.statusUpdated", { status: statusText })}
    </CommonEvent>
  );
};

const AdminstrativeOfficerEventEntry = (props: EventOutputDTO) => {
  const { t } = useTranslation();

  return (
    <CommonEvent>
      {t("case.events.administrativeOfficerAssigned", {
        name: props.content,
      })}
    </CommonEvent>
  );
};

const AttachmentEventEntry = (props: EventOutputDTO) => {
  const { t } = useTranslation();

  return (
    <CommonEvent>
      {t("case.events.attachmentAdded", {
        filename: props.content,
      })}
    </CommonEvent>
  );
};

const IncidentCategoryUpdateEventEntry = (props: EventOutputDTO) => {
  const { t } = useTranslation();

  const incidentCategoryText = t(
    `caseTypes.${props.content?.toLowerCase()}` as any
  );

  return (
    <CommonEvent>
      {t("case.events.incidentCategoryUpdate", {
        incidentCategory: incidentCategoryText,
      })}
    </CommonEvent>
  );
};

const ConflictOfInterestEventType = () => {
  const { t } = useTranslation();

  return <CommonEvent>{t("case.events.conflictOfInterest")}</CommonEvent>;
};

const NotConflictOfInterest = (props: EventOutputDTO) => {
  const { t } = useTranslation();

  return (
    <CommonEvent>
      {t("case.events.notConflictOfInterest", { name: props.createdBy })}
    </CommonEvent>
  );
};

const CaseViewerAssigned = (props: EventOutputDTO) => {
  const { t } = useTranslation();
  return (
    <CommonEvent>
      {t("case.events.caseViewerAssigned", {
        name: props.content
      })}
    </CommonEvent>
  )
}

const CaseViewerUnAssigned = (props: EventOutputDTO) => {
  const { t } = useTranslation();
  return (
    <CommonEvent>
      {t("case.events.caseViewerUnAssigned", {
        name: props.content
      })}
    </CommonEvent>
  )
}

interface CommonEventProps {
  children: ReactNode;
}

const CommonEvent = (props: CommonEventProps) => (
  <Box paddingTop={2}>
    <Typography>{props.children}</Typography>
  </Box>
);

const createEventEntry = (event: EventOutputDTO, key: number) => {
  switch (event.eventType) {
    case EventType.MESSAGE:
      return <MessageEventEntry {...event} key={key} />;
    case EventType.STATUS_UPDATE:
      return <StatusEventEntry {...event} key={key} />;
    case EventType.ADMINISTRATIVE_OFFICER_ASSIGNED:
      return <AdminstrativeOfficerEventEntry {...event} key={key} />;
    case EventType.ATTACHMENT_ADDED:
      return <AttachmentEventEntry {...event} key={key} />;
    case EventType.CONTACT:
      return null;
    case EventType.INCIDENT_CATEGORY_UPDATE:
      return <IncidentCategoryUpdateEventEntry {...event} key={key} />;
    case EventType.CONFLICT_OF_INTEREST:
      return <ConflictOfInterestEventType />;
    case EventType.NOT_CONFLICT_OF_INTEREST:
      return <NotConflictOfInterest {...event} key={key} />;
    case EventType.CASE_VIEWER_ASSIGNED:
      return <CaseViewerAssigned  {...event} key={key} />
    case EventType.CASE_VIEWER_UN_ASSIGNED:
      return <CaseViewerUnAssigned  {...event} key={key} />
    default:
      return <UnknownEventEntry {...event} key={key} />;
  }
};

const MessageEventEntry = (props: EventOutputDTO) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const avatar = "#";

  const timestamp = formatDateAndTime(props.created!);

  return (
    <Box className={classes.messageBox}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="#" src={avatar} />
        </ListItemAvatar>
        <span style={{ display: "block" }}>
          <span style={{ display: "flex" }}>
            <Typography className={classes.senderText} component="h4">
              {renameReporter(props.createdBy, t)}
            </Typography>
            <Typography className={classes.timeText}>{timestamp}</Typography>
          </span>
          <div>
            <Box fontWeight={400} className={classes.messageText}>
              {" "}
              {props.content?.split("\n").map((row) => (
                <div>{row}</div>
              ))}
            </Box>
          </div>
        </span>
      </ListItem>
      <Divider variant="inset" component="li" />
    </Box>
  );
};

export default EventList;
