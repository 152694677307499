import { Divider, Drawer, List, makeStyles } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import TimelineIcon from "@material-ui/icons/Timeline";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import BusinessIcon from "@material-ui/icons/Business";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import FeedbackIcon from "@material-ui/icons/Feedback";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { logOut } from "../../store/authReducer";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import CompanyLogo from "./CompanyLogo";
import MenuButton from "./MenuButton";
import MenuLink from "./MenuLink";
import { isGlobalAdmin, isGlobalAdminOrAdmin } from "../../utils/userUtils";
import { LanguageSwitcher } from "../LanguageSwitcher/LanguageSwitcher";
import { useHistory } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
    "&:last-of-type": {
      marginTop: "auto",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  language: {
    textAlign: "left",
    marginLeft: theme.spacing(1.5),
  },
}));

export const Menu = () => {
  const signedInCustomerId = useAppSelector((state) => state.auth.signedInCustomerId);
  const userRole = useAppSelector((state) => state.auth.user?.customersAndRoles?.find(x => x.customerId === signedInCustomerId)?.role);
  const history = useHistory();

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const showFeedbackcases = useAppSelector((state) => state.branding.branding?.showFeedbackcase);

  const handleLogOut = useCallback(() => {
    dispatch(logOut());
    history.push("");
  }, [dispatch, history]);

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerContainer}>
        <CompanyLogo />
        <List>
          <MenuLink text={t("menu.cases")} to="/cases" icon={<ListIcon />} />
          {showFeedbackcases ?
            <MenuLink
              text={t("menu.feedback")}
              to="/feedback"
              icon={<FeedbackIcon />}
            /> : null}
          <MenuLink
            disabled={false}
            text={t("menu.statistics")}
            to={`/statistics/${signedInCustomerId}/casetype`}
            icon={<TimelineIcon />}
          />
          <Divider />
          <MenuLink
            disabled={isGlobalAdminOrAdmin(userRole)}
            text={t("menu.customerSettings")}
            to={`/customer/settings/${signedInCustomerId}`}
            icon={<SettingsApplicationsIcon />}
          />
          <Divider />
          <MenuLink
            disabled={isGlobalAdmin(userRole)}
            text={t("menu.customers")}
            to="/admin/customers"
            icon={<BusinessIcon />}
          />
        </List>
      </div>
      <div className={classes.drawerContainer}>
        <List>
          <div className={classes.language}>
            <LanguageSwitcher isSettingDefaultValue={false} />
          </div>
          <MenuButton
            text={t("topBar.menu.logOutButton")}
            onClick={handleLogOut}
            icon={<LogoutIcon />}
          />
        </List>
      </div>
    </Drawer>
  );
};
