import React from "react";
import { useTranslation } from "react-i18next";
import Select from "../Inputs/Select";
import { CustomerDTO } from "../../api";
import { AppBar, Box, Button, Container, CssBaseline, makeStyles, Toolbar, Typography } from "@material-ui/core";
import { logSignedInCustomer, setSignedInCustomerId } from "../../store/authReducer";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { LanguageSwitcher } from "../LanguageSwitcher/LanguageSwitcher";
import { NoAccess } from "../MainApp/NoAccess";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  title: {
    flexGrow: 1,
  },
}));

interface ChoseCustomerProps  {
  customers: CustomerDTO[]
} 

export const ChoseCustomer =(props: ChoseCustomerProps) => {
  const classes = useStyles();
  const [signedInCustomer, setSignedInCustomer] = React.useState<string>("");
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector((state) => state.auth.status);

  const choseCustomersLabel = t("logIn.choseCustomer");
  const logIn = t("logIn.submit");

  const saveChanges = () => {
    dispatch(setSignedInCustomerId(signedInCustomer));
    dispatch(logSignedInCustomer(signedInCustomer));
  };

  var customerOptions = props.customers.map(x => ({ value: x.id!, text: x.name?.replace("#","")! }));
  return (
    <div>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}></Typography>
          <LanguageSwitcher isSettingDefaultValue={false} />
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
        <Typography component="h1" variant="h5">
            {choseCustomersLabel}
          </Typography>
        <Box marginTop={4} minWidth={"200px"}>
          <div>
            <Select
            label={""}   
            options={customerOptions}
            onChange={(event) => setSignedInCustomer(event.target.value as string)}
            value={signedInCustomer}
            />
          </div>
          <div>
            <div>
              <Button
                onClick={saveChanges}
                color="primary"
                variant="outlined"
                fullWidth={true}
                disabled={signedInCustomer === null || signedInCustomer === undefined}
              >
                {logIn}
              </Button>
            </div>
          </div>
        </Box>
        {authStatus === "NO_ACCESS" ? <NoAccess /> : null}
        </div>
      </Container>
    </div>
  );
}
