import React from "react";
import { useTranslation } from "react-i18next";
import { ConfirmButton } from "../ConfirmButton/ConfirmButton";

interface DeleteCaseButtonProps {
  onDelete: () => void;
}

export const DeleteCaseButton = (props: DeleteCaseButtonProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmButton
      color="secondary"
      fullWidth={true}
      id="delete-case"
      title={t("case.delete.title")}
      content={t("case.delete.confirm")}
      confirmText={t("case.delete.yes")}
      cancelText={t("common.cancel")}
      onConfirm={props.onDelete}
    >
      {t("case.delete.title")}
    </ConfirmButton>
  );
};
