import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Loading } from "../Loading/Loading";
import { LogTable } from "../LogTable/LogTable";
import { fetchCaseLog } from "../../store/casesReducer";

interface CaseLogProps {
  caseId: string;
}

export const CaseLog = (props: CaseLogProps) => {
  const dispatch = useAppDispatch();
  const caseLog = useAppSelector((state) => state.cases.auditLog);
  const loading = useAppSelector(
    (state) => state.cases.logStatus === "LOG_NOT_LOADED"
  );

  useEffect(() => {
    if (loading) {
      dispatch(fetchCaseLog(props.caseId));
    }
  }, [loading, props.caseId, dispatch]);

  if (loading) return <Loading />;

  return <LogTable contentArray={caseLog} />;
};
