import React, { ReactNode } from "react";
import { Choice, ChoiceDialog } from "./ChoiceDialog";
import { Box, Button } from "@material-ui/core";

export interface EditWithChoiceButtonProps<ChoiceType> {
  title: string;
  editable?: boolean;
  children: ReactNode;
  onChoice: (value: ChoiceType | null) => void;
  getChoices: () => Promise<Choice<any>[]>;
}

export function EditWithChoiceButton <T>(props: EditWithChoiceButtonProps<T>) {
  const [open, setOpen] = React.useState(false);

  const handleOnChoice = (value: T | null) => {
    setOpen(false);
    props.onChoice(value);
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  if (!props.editable) return <>{props.children}</>;

  return (
    <>
      <Button fullWidth={true} variant="outlined" onClick={() => setOpen(true)}>
        <Box textAlign="left">{props.children}</Box>
      </Button>
      <ChoiceDialog
        open={open}
        onChoice={(value) => handleOnChoice(value)}
        onClose={handleOnClose}
        title={props.title}
        getChoices={props.getChoices}
      />
    </>
  );
};
