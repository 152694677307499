import { RoleType } from "../api";

export const isGlobalAdmin = (userRole: RoleType | undefined) => {
    if(userRole !== undefined && userRole === RoleType.GLOBAL_ADMIN) 
      return false;
    else
      return true;
  };
  
export const isGlobalAdminOrAdmin = (userRole: RoleType | undefined) => {
    if(userRole !== undefined && (userRole === RoleType.GLOBAL_ADMIN || userRole === RoleType.ADMIN)) 
      return false;
    else
      return true;
  };
  
export const isAdmin = (userRole: RoleType | undefined) => {
    if(userRole !== undefined &&  userRole === RoleType.ADMIN) 
      return false;
    else
      return true;
  };

export const isUser = (userRole: RoleType | undefined) => {
    if(userRole !== undefined &&  userRole === RoleType.USER) 
      return false;
    else
      return true;
  };