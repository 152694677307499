/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttachmentOutputDTO } from '../models/AttachmentOutputDTO';
import type { AuditLogDTO } from '../models/AuditLogDTO';
import type { CaseInputDTO } from '../models/CaseInputDTO';
import type { CaseOutputDTO } from '../models/CaseOutputDTO';
import type { CaseSimpleOutputDTO } from '../models/CaseSimpleOutputDTO';
import type { ConflictOfInterestDTO } from '../models/ConflictOfInterestDTO';
import type { EventInputDTO } from '../models/EventInputDTO';
import type { EventOutputDTO } from '../models/EventOutputDTO';
import type { NewCaseOutputDTO } from '../models/NewCaseOutputDTO';
import { request as __request } from '../core/request';

export class CasesService {

    /**
     * Get all cases
     * @returns CaseSimpleOutputDTO Returns a list of cases
     * @throws ApiError
     */
    public static async getCases(): Promise<Array<CaseSimpleOutputDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/Cases`,
            errors: {
                400: `If the item is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
            },
        });
        return result.body;
    }

    /**
     * Create a new case
     * @param requestBody 
     * @returns NewCaseOutputDTO Case created
     * @throws ApiError
     */
    public static async postCase(
requestBody?: any,
): Promise<NewCaseOutputDTO> {
        const result = await __request({
            method: 'POST',
            path: `/api/Cases`,
            body: requestBody,
            errors: {
                400: `Id is not null`,
            },
        });
        return result.body;
    }

    /**
     * Get case by id
     * @param id 
     * @param customerToken 
     * @returns CaseOutputDTO Returns a case found by id
     * @throws ApiError
     */
    public static async getCase(
id: string,
customerToken?: string,
): Promise<CaseOutputDTO> {
        const result = await __request({
            method: 'GET',
            path: `/api/Cases/${id}`,
            headers: {
                'Customer-Token': customerToken,
            },
            errors: {
                400: `If the id is empty`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
                404: `If case not found`,
            },
        });
        return result.body;
    }

    /**
     * Update case information by id
     * @param id 
     * @param customerToken 
     * @param requestBody 
     * @returns CaseOutputDTO Case updated
     * @throws ApiError
     */
    public static async putCase(
id: string,
customerToken?: string,
requestBody?: CaseInputDTO,
): Promise<CaseOutputDTO> {
        const result = await __request({
            method: 'PUT',
            path: `/api/Cases/${id}`,
            headers: {
                'Customer-Token': customerToken,
            },
            body: requestBody,
            errors: {
                400: `If the id's is different from eachother`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
                404: `If case is not found`,
            },
        });
        return result.body;
    }

    /**
     * Delete case
     * @param id 
     * @returns void 
     * @throws ApiError
     */
    public static async deleteCase(
id: string,
): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/Cases/${id}`,
            errors: {
                400: `Invalid object`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
                404: `Case not found`,
            },
        });
        return result.body;
    }

    /**
     * Create a new message
     * @param id 
     * @param customerToken 
     * @param requestBody 
     * @returns EventOutputDTO Message created
     * @throws ApiError
     */
    public static async postMessage(
id: string,
customerToken?: string,
requestBody?: EventInputDTO,
): Promise<EventOutputDTO> {
        const result = await __request({
            method: 'POST',
            path: `/api/Cases/${id}/Message`,
            headers: {
                'Customer-Token': customerToken,
            },
            body: requestBody,
            errors: {
                400: `Id is not null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
            },
        });
        return result.body;
    }

    /**
     * Get all events
     * @param id 
     * @param customerToken 
     * @returns EventOutputDTO Returns a list of events
     * @throws ApiError
     */
    public static async getEvents(
id: string,
customerToken?: string,
): Promise<Array<EventOutputDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/Cases/${id}/Events`,
            headers: {
                'Customer-Token': customerToken,
            },
            errors: {
                400: `If the item is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
            },
        });
        return result.body;
    }

    /**
     * Upload attachments
     * @param id 
     * @param requestBody 
     * @returns AttachmentOutputDTO Attachments created
     * @throws ApiError
     */
    public static async postAttachments(
id: string,
requestBody?: any,
): Promise<Array<AttachmentOutputDTO>> {
        const result = await __request({
            method: 'POST',
            path: `/api/Cases/${id}/Attachments`,
            body: requestBody,
            errors: {
                400: `Id is not null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
            },
        });
        return result.body;
    }

    /**
     * Get all attachments
     * @param id 
     * @returns AttachmentOutputDTO Returns a list of attachments
     * @throws ApiError
     */
    public static async getAttachments(
id: string,
): Promise<Array<AttachmentOutputDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/Cases/${id}/Attachments`,
            errors: {
                400: `If the item is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
            },
        });
        return result.body;
    }

    /**
     * Download attachment
     * @param id 
     * @param attachmentId 
     * @param customerToken 
     * @returns string Returns a downloadable attachment
     * @throws ApiError
     */
    public static async getAttachment(
id: string,
attachmentId: string,
customerToken?: string,
): Promise<string> {
        const result = await __request({
            method: 'GET',
            path: `/api/Cases/${id}/Attachments/${attachmentId}`,
            headers: {
                'Customer-Token': customerToken,
            },
            errors: {
                400: `If the item is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
            },
        });
        return result.body;
    }

    /**
     * Check if user is in a conflict of interest situation or not
     * @param id 
     * @returns ConflictOfInterestDTO Returns conflict of interest status
     * @throws ApiError
     */
    public static async getConflictOfInterest(
id: string,
): Promise<ConflictOfInterestDTO> {
        const result = await __request({
            method: 'GET',
            path: `/api/Cases/${id}/ConflictOfInterest`,
            errors: {
                400: `If the item is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
            },
        });
        return result.body;
    }

    /**
     * Set if user is in a conflict of interest situation or not
     * @param id 
     * @param requestBody 
     * @returns ConflictOfInterestDTO Returns conflict of interest status
     * @throws ApiError
     */
    public static async setConflictOfInterest(
id: string,
requestBody?: ConflictOfInterestDTO,
): Promise<ConflictOfInterestDTO> {
        const result = await __request({
            method: 'POST',
            path: `/api/Cases/${id}/ConflictOfInterest`,
            body: requestBody,
            errors: {
                400: `If the item is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
            },
        });
        return result.body;
    }

    /**
     * Get all audit logs
     * @param id 
     * @returns AuditLogDTO Returns a auditlog  for case
     * @throws ApiError
     */
    public static async getAuditLogs(
id: string,
): Promise<Array<AuditLogDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/Cases/${id}/AuditLog`,
            errors: {
                400: `If id is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
            },
        });
        return result.body;
    }

}