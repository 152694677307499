import { Tab, Tabs } from "@material-ui/core";
import React, { ReactNode, useState } from "react";
import { TabPanel } from "../TabPanel/TabPanel";

interface TabHandlerProps {
  tabHeaderOne: string;
  tabHeaderTwo: string;
  firstTabContent: ReactNode;
  secondTabContent: ReactNode;
}

export const TabHandler = (props: TabHandlerProps) => {
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label={props.tabHeaderOne} />
        <Tab label={props.tabHeaderTwo} />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        {props.firstTabContent}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {props.secondTabContent}
      </TabPanel>
    </div>
  );
};
