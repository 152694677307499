import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { CustomerDTO } from "../../api";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { generatePassphrase } from "../../store/customersReducer";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontSize: 25,
    marginTop: 10,
    marginBottom: 10,
  },
  dialogInfo: {
    fontSize: 18,
    marginTop: 10,
    marginBottom: 10,
  },
  headerField: {
    fontSize: 20,
    marginTop: 10,
    marginBottom: 10,
  },
  okButton: {
    margin: theme.spacing(2),
  },
}));

interface UpdatePassphraseProps {
  customer: CustomerDTO;
  setCustomer: Function;
}

export const ChangePassphrase = (props: UpdatePassphraseProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogPwdOpen, setDialogPwdOpen] = useState(false);
  const [dialogFaildOpen, setDialogFaildOpen] = useState(false);
  const [customerToken, setToken] = useState("");

  const customerId = props.customer.id!;
  const customerName = props.customer.name?.replace("#", "");

  const generatedPassphrase = useAppSelector(
    (state) => state.customers.passphrase
  );
  const faild = useAppSelector(
    (state) => state.customers.passphraseStatus === "PASS_REJECTED"
  );
  const changed = useAppSelector(
    (state) => state.customers.passphraseStatus === "PASS_CHANGED"
  );
  
  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    resetAllFields();
    setDialogOpen(false);
  };

  const handlePwdClose = () => {
    resetAllFields();
    setDialogPwdOpen(false);
  };

  const handleFaildClose = () => {
    resetAllFields();
    setDialogOpen(true);
    setDialogFaildOpen(false);
  };

  const handleGenerate = () => {
    dispatch(generatePassphrase({ customerId, customerToken }));
    resetAllFields();
    setDialogOpen(false);
    setDialogPwdOpen(true);
    setDialogFaildOpen(true);
  };

  const resetAllFields = () => {
    setToken("");
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    setToken(value);
  };

  return (
    <div>
      {changed ? (
        <Dialog open={dialogPwdOpen}>
          <DialogContent>
            <Typography className={classes.dialogTitle}>
              {t("changeCustomerPassphrase.titleNewPassphrase")}
            </Typography>
            <Typography className={classes.dialogInfo}>
              {t("changeCustomerPassphrase.newPassphraseText", { customerName })}
            </Typography>
            <Typography className={classes.headerField}>
              {t("addCustomer.customerPassword")}
            </Typography>
            <TextField
              disabled={true}
              value={generatedPassphrase}
              variant="outlined"
              size="medium"
              fullWidth
            />
          </DialogContent>
          <Button
            className={classes.okButton}
            variant="outlined"
            color="primary"
            onClick={handlePwdClose}
          >
          {t("customerSettings.OK")}
        </Button>
        </Dialog>
      ) : null}
      {faild ? (
        <Dialog open={dialogFaildOpen}>
          <DialogContent>
            <Typography className={classes.dialogTitle}>
              {t("changeCustomerPassphrase.title")}
            </Typography>
            <Typography className={classes.dialogInfo}>
              {t("changeCustomerPassphrase.faildText", { customerName })}
            </Typography>
          </DialogContent>
          <Button
            className={classes.okButton}
            variant="outlined"
            color="primary"
            onClick={handleFaildClose}
          >
          {t("customerSettings.OK")}
        </Button>
        </Dialog>
      ) : null}
      <Button
        variant="outlined"
        style={{ float: "right" }}
        onClick={handleOpen}
      >
        {t("default:changeCustomerPassphrase.button")}
      </Button>
      <Dialog open={dialogOpen}>
        <DialogContent>
          <Typography className={classes.dialogTitle}>
            {t("changeCustomerPassphrase.title", { customerName })}
          </Typography>
          <Typography className={classes.dialogInfo}>
            {t("changeCustomerPassphrase.warningText", { customerName })}
          </Typography>
          <Typography className={classes.dialogInfo}>
            {t("changeCustomerPassphrase.irrevocableText")}
          </Typography>
          <TextField
            autoFocus
            size="medium"
            fullWidth
            value={customerToken}
            onChange={handleChange}
            label={t("changeCustomerPassphrase.currentPassword")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("customerSettings.abort")}
          </Button>
          <Button onClick={handleGenerate} color="primary">
            {t("changeCustomerPassphrase.yesChange")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
