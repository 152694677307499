/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CaseSimpleOutputDTO } from '../models/CaseSimpleOutputDTO';
import { request as __request } from '../core/request';

export class FeedbackService {

    /**
     * Get all feedback
     * @returns CaseSimpleOutputDTO Returns a list of feedback cases
     * @throws ApiError
     */
    public static async getFeedback(): Promise<Array<CaseSimpleOutputDTO>> {
        const result = await __request({
            method: 'GET',
            path: `/api/Feedback`,
            errors: {
                400: `If the item is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
            },
        });
        return result.body;
    }

}