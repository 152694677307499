/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StatisticsIncidentCategoryDTOStatisticsResultDTO } from '../models/StatisticsIncidentCategoryDTOStatisticsResultDTO';
import { request as __request } from '../core/request';

export class StatisticsService {

    /**
     * Get statistics for all incident-categories during a period of time
     * @param customerId 
     * @param startDate 
     * @param endDate 
     * @returns StatisticsIncidentCategoryDTOStatisticsResultDTO Returns a list of incidentcategories statistics
     * @throws ApiError
     */
    public static async getStatisticsAllIncidentCategories(
customerId: string,
startDate?: string,
endDate?: string,
): Promise<StatisticsIncidentCategoryDTOStatisticsResultDTO> {
        const result = await __request({
            method: 'GET',
            path: `/api/Statistics/${customerId}/IncidentCategory`,
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
            errors: {
                400: `If the item is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
            },
        });
        return result.body;
    }

}