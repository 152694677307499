import React from "react";
import { Button } from "@material-ui/core";
import { GridRowData } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { CustomerDTO } from "../../api";
import { ConfirmationDialog } from "../ConfirmDialog/ConfirmDialog";

interface DeleteUserProps {
  relatedUser: GridRowData;
  customer: CustomerDTO;
  setCustomer: Function;
}

export const DeleteUser = (props: DeleteUserProps) => {
  const { t } = useTranslation();

  let tempCustomer: CustomerDTO = JSON.parse(JSON.stringify(props.customer));

  const [dialogOpen, setDialogOpen] = React.useState(false);

  function handleOpen() {
    setDialogOpen(true);
  }

  function handleClose() {
    setDialogOpen(false);
  }

  const handleDeletion = () => {
    const user = tempCustomer.users?.find((x) => x.id === props.relatedUser.id);
    if ((tempCustomer.users?.indexOf(user!, 0), 1 > -1))
      tempCustomer.users!.splice(tempCustomer.users?.indexOf(user!, 0)!, 1);

    props.setCustomer(tempCustomer);
    setDialogOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        variant="text"
        size="medium"
        color="secondary"
        style={{ marginLeft: 16 }}
      >
        {<RemoveCircleIcon color="primary" />}
      </Button>
      <ConfirmationDialog
        id={"deleteUser"}
        open={dialogOpen}
        onCancel={handleClose}
        onConfirm={handleDeletion}
        title={t("customerSettings.deleteUserPrompt")}
        content={t("customerSettings.deleteUserConfimation")}
        confirmText={t("customerSettings.deleteUserYes")}
        cancelText={t("customerSettings.deleteUserNo")}
      ></ConfirmationDialog>
    </div>
  );
};
