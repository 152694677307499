import { createAction, createReducer } from "@reduxjs/toolkit";
import { OpenAPI } from "../api";

interface DevState {
  errorMessage?: string;
  backendUrl: string;
}

export const setBackendUrl = createAction<string>("dev/setBackendUrl");
export const setErrorMessage = createAction<string>("dev/setErrorMessage");
export const clearErrorMessage = createAction("dev/clearErrorMessage");

const initialState = {
  backendUrl: OpenAPI.BASE,
} as DevState;

export const devReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setBackendUrl, (state, action) => {
      state.backendUrl = action.payload;
      OpenAPI.BASE = action.payload;
    })
    .addCase(setErrorMessage, (state, action) => {
      state.errorMessage = action.payload;
    })
    .addCase(clearErrorMessage, (state) => {
      state.errorMessage = undefined;
    });
});
