import { darkTheme, lightTheme } from "../AppTheme";
import { ImageVisibility } from "../api";
import { shouldUseDarkModeFromColor } from "../utils/colorUtils";
import { useAppSelector } from "./redux";

export function useBranding(hideImageAvailable = false) {
  const name = useAppSelector((state) => hideImageAvailable && state.branding.branding?.imageVisibility == ImageVisibility.HIDE_ON_LANDING_PAGE ? "" : state.branding.branding?.imageVisibility == ImageVisibility.HIDE_COMPANY_NAME || state.branding.branding?.name?.startsWith("#") ? "" : state.branding.branding?.name);
  const logotype = useAppSelector((state) => hideImageAvailable && state.branding.branding?.imageVisibility == ImageVisibility.HIDE_ON_LANDING_PAGE ? null : state.branding.branding?.image);
  const showFeedbackcases = useAppSelector((state) => state.branding.branding?.showFeedbackcase);
  const inAssociationWith = useAppSelector((state) => state.branding.branding?.inAssociationWith);
  const color = useAppSelector(
    (state) => state.branding.branding?.backgroundColor
  );

  const useDarkTheme =
    color !== null && shouldUseDarkModeFromColor(color as string);
  const theme = useDarkTheme ? darkTheme : lightTheme;

  return {
    name,
    logotype,
    showFeedbackcases,
    inAssociationWith,
    color,
    theme,
  };
}
