import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Checkbox, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import { ChromePicker } from "react-color";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { CustomerDTO, ImageVisibility, Language } from "../../api";
import { AvailableLanguages, getLanguageType, LanguageSwitcher } from "../LanguageSwitcher/LanguageSwitcher";

const useStyles = makeStyles((theme) => ({
  fieldPlaceholder: {
    fontSize: 20,
    marginRight: 20,
  },
  fieldHolder: {
    display: "flex",
    marginBottom: 30,
    alignContent: "center",
  },
  logo: {
    maxWidth: 300,
  },
  SketchPickerFieldHolder: {
    marginBottom: 20,
  },
  colorBox: {
    width: 100,
    height: 100,
  },
}));

interface CustomerProps {
  customer: CustomerDTO;
  setCustomer: Function;
  originalColor: string;
}

export const Customize = (props: CustomerProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let tempCustomer: CustomerDTO = JSON.parse(JSON.stringify(props.customer));

  const [colorClickedState, setChangeColorClickedState] = React.useState(false);
  let showFeedbackcasesState: boolean;
  
  if (props != null && props.customer != null && props.customer.showFeedbackcase != null) {
    showFeedbackcasesState = props.customer.showFeedbackcase!;
  } else {
    showFeedbackcasesState = true;
  }

  const handleColorClicked = () => {
    if (colorClickedState) {
      setChangeColorClickedState(false);
    } else {
      setChangeColorClickedState(true);
    }
  };

  const handleName = (event: React.ChangeEvent<{ value: unknown }>) => {
    tempCustomer.name = event.target.value as string;
    props.setCustomer(tempCustomer);
  };

  const handleInAssociationWith = (event: React.ChangeEvent<{ value: unknown }>) => {
    tempCustomer.inAssociationWith = event.target.value as string;
    props.setCustomer(tempCustomer);
  };
  
  const handleNotificationEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    tempCustomer.notificationEmail = event.target.value as string;
    props.setCustomer(tempCustomer);
  };

  const handleLogoURL = (event: React.ChangeEvent<{ value: unknown }>) => {
    tempCustomer.image = event.target.value as string;
    props.setCustomer(tempCustomer);
  };

  const handleColor = (color: string) => {
    tempCustomer.backgroundColor = color;
    props.setCustomer(tempCustomer);
  };

  const handleShowFeedbackcases = () => {
    tempCustomer.showFeedbackcase = !showFeedbackcasesState;
    props.setCustomer(tempCustomer);
    showFeedbackcasesState = tempCustomer.showFeedbackcase;
  };

  const saveBackgroundColorChanges = () => {
    setChangeColorClickedState(false);
  };

  const revertBackgroundColorChanges = () => {
    tempCustomer.backgroundColor = props.originalColor;
    props.setCustomer(tempCustomer);
    setChangeColorClickedState(false);
  };

  const handleDefaultLanguageChange = (languageCode: AvailableLanguages) => {
    tempCustomer.defaultLanguage = getLanguageType(languageCode) as Language;
    props.setCustomer(tempCustomer);
  }

  const handleLogotypeVisibilityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    tempCustomer.imageVisibility = event.target.value as ImageVisibility;
    props.setCustomer(tempCustomer);
  };

  return (
    <div>
      <Typography style={{ fontSize: 30, display: "flex", marginBottom: 10 }}>
        {t("default:customerSettings.customize")}
      </Typography>
      <div className={classes.fieldHolder}>
        <Typography className={classes.fieldPlaceholder}>
          {t("customerSettings.companyName") + ":"}
        </Typography>
        <form noValidate autoComplete="off">
          <TextField
            id="companyName"
            variant="standard"
            size="small"
            value={props.customer.name}
            onChange={handleName}
            style={{ width: "20vh" }}
          />
        </form>
      </div>
      <div className={classes.fieldHolder}>
        <Typography className={classes.fieldPlaceholder}>
          {t("customerSettings.logotypeVisibility") + ":"}
        </Typography>
        <form noValidate autoComplete="off">
        {props?.customer && props?.customer?.imageVisibility && <Select
              id="logotypeVisibilitySelect"
              value={props?.customer?.imageVisibility}
              onChange={handleLogotypeVisibilityChange}
              style={{ width: "20vh" }}
            >
                <MenuItem value={ImageVisibility.SHOW}>
                  {t("logotypeVisibility.show")}
                </MenuItem> : 
                <MenuItem value={ImageVisibility.HIDE_ON_LANDING_PAGE}>
                  {t("logotypeVisibility.hideOnLandingPage")}
                </MenuItem>
                <MenuItem value={ImageVisibility.HIDE_COMPANY_NAME}>
                  {t("logotypeVisibility.hideCompanyName")}
                </MenuItem>
            </Select>}
        </form>
      </div>
      <div className={classes.fieldHolder}>
        <Typography className={classes.fieldPlaceholder}>
          {t("customerSettings.inAssociationWith") + ":"}
        </Typography>
        <form noValidate autoComplete="off">
          <TextField
            id="inAssociationWith"
            variant="standard"
            size="small"
            placeholder={t("customerSettings.addInAssociationWith")}
            value={props.customer.inAssociationWith ?? ""}
            onChange={handleInAssociationWith}
            style={{ width: "20vh" }}
          />
        </form>
      </div>
      <div className={classes.fieldHolder}>
        <Typography className={classes.fieldPlaceholder}>
          {t("customerSettings.logotypeURL") + ":"}
        </Typography>
        <TextField
          id="logoURLTextField"
          variant="standard"
          size="small"
          placeholder={t("customerSettings.pasteUrlLogo")}
          fullWidth
          value={props.customer.image ?? ""}
          onChange={handleLogoURL}
          style={{ width: "35vh" }}
        />
      </div>
      <div className={classes.fieldHolder}>
        <img className={classes.logo} src={props.customer.image!} alt={props.customer.name ?? ""}></img>
      </div>
      <div className={classes.fieldHolder}>
        <Typography className={classes.fieldPlaceholder}>
          {t("customerSettings.backgroundColor") + ":"}
        </Typography>
      </div>
      <div className={classes.SketchPickerFieldHolder}>
        {colorClickedState ? (
          <div>
            <ChromePicker
              color={props.customer.backgroundColor!}
              onChange={(color) => handleColor(color.hex)}
            />
          </div>
        ) : (
          <Box
            className={classes.colorBox}
            style={{ backgroundColor: props.customer.backgroundColor! }}
          ></Box>
        )}
      </div>
      <div className={classes.fieldHolder}>
        {colorClickedState ? (
          <div>
            <Button
              variant="outlined"
              onClick={saveBackgroundColorChanges}
              style={{ marginRight: 16 }}
            >
              {t("customerSettings.OK")}
            </Button>
            <Button variant="outlined" onClick={revertBackgroundColorChanges}>
              {t("customerSettings.revertColor")}
            </Button>
          </div>
        ) : (
          <Button variant="outlined" onClick={handleColorClicked}>
            {t("customerSettings.modifyColor")}
          </Button>
        )}
      </div>
      <div className={classes.fieldHolder}>
        <Typography className={classes.fieldPlaceholder}>
          {t("customerSettings.showFeedbackcase") + ":"}
        </Typography>
        <Checkbox 
          color="primary"
          checked={showFeedbackcasesState} 
          onClick={handleShowFeedbackcases}
          style={{padding: 5}} 
        />
      </div>
      <div className={classes.fieldHolder}>
        <Typography className={classes.fieldPlaceholder} hidden = {!showFeedbackcasesState}>
          {t("customerSettings.notificationEmail") + ":"}
        </Typography>
        <form noValidate autoComplete="off" hidden = {!showFeedbackcasesState}>
          <TextField
            id="notificationEmail"
            variant="standard"
            size="small"
            placeholder={t("customerSettings.addNotificationEmail")}
            value={props.customer.notificationEmail ?? ""}
            onChange={handleNotificationEmail}
            style={{ width: "20vh" }}
          />
        </form>
      </div>
      <div className={classes.fieldHolder}>
        <Typography className={classes.fieldPlaceholder}>
          {t("customerSettings.chooseDefaultLanguage") + ":"}
        </Typography>
        <LanguageSwitcher isSettingDefaultValue={true} onChange={handleDefaultLanguageChange}/>
      </div>
    </div>
  );
};
