import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { OpenAPI, UserDTO, UsersService } from "../api";

export type AuthStatus =
  | "LOGGED_OUT"
  | "LOGGED_IN"
  | "LOGGING_IN_CHECK_CUSTOMER"
  | "NO_ACCESS"
  | "LOGGING_IN"
  | "FOLLOW_UP_CREDENTIALS_SET";
interface AuthState {
  idToken: string;
  status: AuthStatus;
  user?: UserDTO;
  signedInCustomerId: string;
  customerCredentials?: CustomerCredentials;
}

interface CustomerCredentials {
  customer: string;
  token: string;
}

interface FollowUpCredentials {
  id: string;
  passphrase: string;
}

export const setToken = createAction<string>("auth/setToken");
export const logIn = createAsyncThunk("auth/logIn", async () => {
  return await UsersService.getCurrentUser();
});
export const setSignedInCustomerId = createAction<string>("auth/setSignedInCustomerId");
export const logSignedInCustomer = createAsyncThunk("auth/choseCustomer", async (customerId: string) => {
    return await UsersService.logSignedInCustomer(customerId);
});

export const setFollowUpCredentials = createAction<FollowUpCredentials>(
  "auth/setFollowUpCredentials"
);

export const setCustomerToken = createAction<CustomerCredentials>(
"auth/setCustomerToken"
);

export const resetCustomerToken = createAction("auth/resetCustomerToken");

export const logOut = createAction("auth/logOut");

const initialState = {
  status: "LOGGED_OUT",
} as AuthState;

export const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setToken, (state, action) => {
      state.idToken = action.payload;
      OpenAPI.TOKEN = state.idToken;
    })
    .addCase(logIn.fulfilled, (state, action) => {
      state.status = "LOGGING_IN_CHECK_CUSTOMER";
      state.user = action.payload;
    })
    .addCase(logIn.pending, (state) => {
      state.status = "LOGGING_IN";
    })
    .addCase(logIn.rejected, (state) => {
      state.status = "NO_ACCESS";
    })
    .addCase(setSignedInCustomerId, (state, action) => {
      state.signedInCustomerId = action.payload;
      OpenAPI.SIGNEDINCUSTOMERID = state.signedInCustomerId;
    })
    .addCase(logSignedInCustomer.fulfilled, (state, action) => {
      state.status = "LOGGED_IN";
      state.signedInCustomerId = action.meta.arg;
      OpenAPI.SIGNEDINCUSTOMERID = state.signedInCustomerId;
    })
    .addCase(logSignedInCustomer.pending, (state) => {
      state.status = "LOGGING_IN";
    })
    .addCase(logSignedInCustomer.rejected, (state) => {
      state.status = "NO_ACCESS";
    })
    .addCase(setFollowUpCredentials, (state, action) => {
      state.status = "FOLLOW_UP_CREDENTIALS_SET";
      OpenAPI.USERNAME = action.payload.id;
      OpenAPI.PASSWORD = action.payload.passphrase;
    })
    .addCase(setCustomerToken, (state, action) => {
      state.customerCredentials = action.payload;
    })
    .addCase(resetCustomerToken, (state) => {
      state.customerCredentials = undefined;
    })
    .addCase(logOut, (state) => {
      state.status = "LOGGED_OUT";
      state.idToken = "";
      state.user = undefined;
      state.signedInCustomerId = "";
      OpenAPI.TOKEN = undefined;
      OpenAPI.USERNAME = undefined;
      OpenAPI.PASSWORD = undefined;
      OpenAPI.SIGNEDINCUSTOMERID = undefined;
    });
});
