import { ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import React, { ReactNode } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";

const useMenuItemStyles = makeStyles((theme) => ({
  MenuItem: {
    padding: theme.spacing(2),
    "&.selected": {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      borderStyle: "solid",
      borderLeft: 3,
    },
  },
}));

interface MenuLinkProps {
  text: string;
  to: string;
  icon: ReactNode;
  disabled?: boolean;
}
const MenuLink = (props: MenuLinkProps) => {
  const location = useLocation();
  const classes = useMenuItemStyles();
  return (
    <MenuItem
      disabled={props.disabled}
      button
      key={props.text}
      component={RouterLink}
      to={props.to}
      classes={{ root: classes.MenuItem, selected: "selected" }}
      selected={location.pathname === props.to}
    >
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText primary={props.text} />
    </MenuItem>
  );
};
export default MenuLink;
