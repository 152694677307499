import { Button, PropTypes } from "@material-ui/core";
import React, { ReactNode } from "react";
import {
  ConfirmationDialog,
} from "../ConfirmDialog/ConfirmDialog";

interface ConfirmButtonProps {
  color?: PropTypes.Color;
  fullWidth?: boolean;
  id: string;
  onConfirm: () => void;
  title: string;
  content: string;
  confirmText: string;
  cancelText: string;
  children?: ReactNode;
  disabled?: boolean;
  shouldShowConfirmDialog: () => boolean;
}

export const ConfirmButton = (props: ConfirmButtonProps) => {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

  return (
    <>
      <Button
        disabled={props.disabled}
        variant="contained"
        color={props.color}
        fullWidth={props.fullWidth}
        onClick={(e) => {
          e.preventDefault();

          if(props.shouldShowConfirmDialog()){
            setDialogOpen(true);
          }
        }}
        type="submit"
      >
        {props.children}
      </Button>
      <ConfirmationDialog
        open={dialogOpen}
        title={props.title}
        content={props.content}
        confirmText={props.confirmText}
        cancelText={props.cancelText}
        onCancel={() => setDialogOpen(false)}
        onConfirm={() => {
          props.onConfirm();
        }}
        id={props.id}
      />
    </>
  );
};

ConfirmButton.defaultProps = {
  shouldShowConfirmDialog: () => {
    return true;
  },
}