import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { useTranslation } from "react-i18next";
import { makeStyles, Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { UsersTable } from "./UsersTable";
import { Customize } from "./Customize";
import { AddUser } from "./AddUser";
import { ChangePassphrase } from "./ChangePassphrase";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchCustomer, editCustomer } from "../../store/customersReducer";
import { Loading } from "../Loading/Loading";
import { useParams } from "react-router";
import { CustomerDTO } from "../../api";
import { DeleteCustomer } from "./DeleteCustomer";
import { CustomerLog } from "./CustomerLog";
import { TabHandler } from "../TabHandler/TabHandler";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    maxWidth: 2000,
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3, 0, 3, 0),
  },
  customerSettingsTitle: {
    fontSize: 40,
    flexGrow: 1,
    fontWeight: "lighter",
    display: "flex",
    marginRight: theme.spacing(2),
  },
  logo: {
    width: 250,
    marginBottom: 20,
  },
  fieldHolder: {
    display: "flex",
    alignContent: "center",
    marginBottom: 30,
  },
  buttonHolder: {
    display: "flex",
    alignContent: "center",
    marginRight: 30,
  },
  tabPanel: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
}));

interface CustomerURL {
  id: string;
}

export const CustomerSettings = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const customerUrl = useParams() as CustomerURL;

  const dispatch = useAppDispatch();
  const customer = useAppSelector((state) => state.customers.details);
  const loading = useAppSelector(
    (state) => state.customers.status === "LOADING_DETAILS"
  );

  const [globalEditState, setGlobalEditState] = useState(false);
  const [customerState, setCustomerState] = useState(customer);
  useEffect(() => {
    if (JSON.stringify(customer) !== JSON.stringify(customerState))
      setGlobalEditState(true);
    else setGlobalEditState(false);
  }, [customer, customerState]);

  useEffect(() => {
    dispatch(fetchCustomer(customerUrl.id));
  }, [customerUrl.id, dispatch]);

  useEffect(() => {
    setCustomerState(customer);
  }, [customer]);

  const saveChanges = () => {
    const customerCopy: CustomerDTO = JSON.parse(JSON.stringify(customerState));
    customerCopy?.users?.forEach((e) => {
      if (e.id?.startsWith("newUser")) delete e.id;
    });

    setGlobalEditState(false);
    dispatch(editCustomer(customerCopy)).then(() => {
      dispatch(fetchCustomer(customerUrl.id));
    });
  };

  if (loading) return <Loading />;

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.customerSettingsTitle}>
          {t("default:customerSettings.customerSettings")}
        </Typography>
      </Toolbar>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          {customerState! ? (
            <Customize
              customer={customerState}
              setCustomer={setCustomerState}
              originalColor={customer?.backgroundColor!}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} sm={8}>
          <div>
            {customerState! ? (
              <AddUser
                setCustomer={setCustomerState}
                customer={customerState}
              />
            ) : null}
            <Typography
              style={{ fontSize: 30, display: "flex", marginBottom: 10 }}
            >
              {t("customerSettings.userAndLog")}
            </Typography>
          </div>
          <TabHandler
            tabHeaderOne={t("customerSettings.user")}
            tabHeaderTwo={t("customerSettings.log")}
            firstTabContent={
              customerState?.users! ? (
                <UsersTable
                  users={customerState?.users}
                  customer={customerState}
                  setCustomer={setCustomerState}
                />
              ) : null
            }
            secondTabContent={<CustomerLog customerId={customerUrl.id} />}
          />
        </Grid>
      </Grid>
      <div className={classes.fieldHolder}>
        <div style={{ float: "right" }}>
          <Button
            onClick={saveChanges}
            variant="contained"
            color="primary"
            size="large"
            disabled={!globalEditState}
          >
            {t("customerSettings.saveSettings")}
          </Button>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div className={classes.buttonHolder}>
          {customerState! ? (
            <ChangePassphrase
                  setCustomer={setCustomerState}
                  customer={customerState}
                />
          ): null}
        </div>
        {customerState! ? (
          <DeleteCustomer customerToDelete={customerState} />
        ) : null}
      </div>
    </div>

  );
};
