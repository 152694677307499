import {
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomerDTO, NewCustomerOutputDTO } from "../../api";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontSize: 25,
    marginTop: 10,
    marginBottom: 10,
  },
  dialogInfo: {
    fontSize: 18,
    marginTop: 10,
    marginBottom: 10,
  },
  customerIdField: {
    fontSize: 20,
    marginTop: 10,
    marginBottom: 10,
  },
  okButton: {
    margin: theme.spacing(2),
  },
}));

interface CustomerCreatedProps {
  newCustomer: NewCustomerOutputDTO;
  customerList: CustomerDTO[];
  updateCustomerList: Function;
}

export const CustomerCreated = (props: CustomerCreatedProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(true);

  const handleClose = () => {
    const customerListCopy: CustomerDTO[] = JSON.parse(
      JSON.stringify(props.customerList)
    );
    customerListCopy.push(props.newCustomer.customer!);
    props.updateCustomerList(customerListCopy);
    setDialogOpen(false);
  };
  return (
    <div>
      <Dialog open={dialogOpen}>
        <DialogContent>
          <Typography className={classes.dialogTitle}>
            {t("addCustomer.newCustomerCreated")}
          </Typography>
          <Typography className={classes.dialogInfo}>
            {t("addCustomer.newCustomerCreatedInfo")}
          </Typography>
          <Typography className={classes.customerIdField}>
            {t("customers.customerId")}
          </Typography>
          <TextField
            disabled={true}
            value={props.newCustomer.customer?.id}
            variant="outlined"
            fullWidth
          />
          <Typography className={classes.customerIdField}>
            {t("addCustomer.customerPassword")}
          </Typography>
          <TextField
            disabled={true}
            value={props.newCustomer.passphrase}
            variant="outlined"
            size="medium"
            fullWidth
          />
        </DialogContent>
        <Button
          className={classes.okButton}
          variant="outlined"
          color="primary"
          onClick={handleClose}
        >
          {t("customerSettings.OK")}
        </Button>
      </Dialog>
    </div>
  );
};
