import React from "react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { CustomerDTO, RoleType, UserDTO } from "../../api";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";

interface AddUserProps {
  customer: CustomerDTO;
  setCustomer: Function;
}

export const AddUser = (props: AddUserProps) => {
  const { t } = useTranslation();
  let tempCustomer: CustomerDTO = JSON.parse(JSON.stringify(props.customer));

  const [dialogOpen, setDialogOpen] = useState(false);
  const [newUsersAmount, setNewUsersAmount] = useState(0);

  const [userNameState, setUserNameState] = React.useState("");
  const [firstNameState, setFirstNameState] = React.useState("");
  const [lastNameState, setLastNameState] = React.useState("");

  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    resetAllFields();
    setDialogOpen(false);
  };

  const handleAdd = () => {
    setNewUsersAmount(newUsersAmount + 1);
    let newUser: UserDTO = {
      id: `newUser${newUsersAmount}`,
      firstName: firstNameState,
      lastName: lastNameState,
      emailAddress: userNameState,
      customersAndRoles: 
      [{
        customerId: props.customer.id,
        role: RoleType.USER
      }]
    };
    tempCustomer.users?.push(newUser);
    props.setCustomer(tempCustomer);
    resetAllFields();
    setDialogOpen(false);
  };

  const resetAllFields = () => {
    setUserNameState("");
    setFirstNameState("");
    setLastNameState("");
  };

  return (
    <div>
      <Button
        variant="outlined"
        style={{ float: "right" }}
        onClick={handleOpen}
      >
        {"+ " + t("default:customerSettings.addUser")}
      </Button>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>{t("customerSettings.createUserPrompt")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("customerSettings.userName")}
          </DialogContentText>
          <TextField
            variant="outlined"
            size="medium"
            fullWidth
            value={userNameState}
            onChange={(event) => setUserNameState(event.target.value as string)}
            style={{ width: "40vh", marginBottom: 10 }}
          />
          <DialogContentText>{t("user.firstName")}</DialogContentText>
          <TextField
            variant="outlined"
            size="medium"
            fullWidth
            value={firstNameState}
            onChange={(event) =>
              setFirstNameState(event.target.value as string)
            }
            style={{ width: "40vh", marginBottom: 10 }}
          />
          <DialogContentText>{t("user.lastName")}</DialogContentText>
          <TextField
            variant="outlined"
            size="medium"
            fullWidth
            value={lastNameState}
            onChange={(event) => setLastNameState(event.target.value as string)}
            style={{ width: "40vh" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("customerSettings.abort")}
          </Button>
          <Button onClick={handleAdd} color="primary" autoFocus>
            {t("customerSettings.OK")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
