/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AuditLogType {
    SIGNIN = 'Signin',
    CREATE = 'Create',
    UPDATE = 'Update',
    READ = 'Read',
    DELETE = 'Delete',
}