import { Box, Divider } from "@material-ui/core";
import React from "react";

export const StandardDivider = () => {
  return (
    <Box marginBottom={3} marginTop={3}>
      <Divider />
    </Box>
  );
};
