import { Box, FormControl, FormHelperText, TextField } from "@material-ui/core";
import React from "react";

export interface InputTextFieldProps {
  value: string | undefined;
  label: string;
  helptext?: string;
  placeholder?: string;
  multiline?: boolean;
  disabled?: boolean;
  focused?: boolean;
  autoFocus?: boolean;
  type?: string;
  onBlur?: () => void;
  onChange: (value: string) => void;
  error?: boolean;
  ariaLabel?: string;
  ariaRequired?: boolean
}

export const InputTextField = (props: InputTextFieldProps) => {
  return (
    <Box marginBottom={4}>
      <FormControl fullWidth={true} variant="outlined">
        <TextField
          id={`${props.label.toLowerCase()}-id`}
          label={props.label}
          variant="outlined"
          type={props.type}
          multiline={props.multiline}
          placeholder={props.placeholder}
          value={props.value}
          disabled={props.disabled}
          onChange={(event) => props.onChange(event.target.value as string)}
          rows={props.multiline ? 4 : 1}
          InputLabelProps={{
            shrink: true,
            "aria-label": props.ariaLabel ? props.ariaLabel : undefined
          }}
          autoFocus={props.autoFocus}
          focused={props.focused}
          fullWidth={true}
          onBlur={props.onBlur}
          error={props.error}
          aria-required={props.ariaRequired ? props.ariaRequired : undefined}
          aria-describedby={`${props.label.toLowerCase()}-description-id`}
          aria-invalid={props.error ? props.error : undefined}
        />
        {props.error ? <FormHelperText id={`${props.label.toLowerCase()}-description-id`} error={true}>{props.helptext}</FormHelperText> : null}
      </FormControl>
    </Box>

  );
};
