import {
  createStyles,
  Box,
  Button,
  List,
  makeStyles,
  TextField,
  Theme
} from "@material-ui/core";
import { EventOutputDTO } from "../../api";

import SendIcon from "@material-ui/icons/Send";
import EventList from "./EventList";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { InputTextField } from "../Inputs/InputTextField";
import Typography from "@material-ui/core/Typography";
import { StandardDivider } from "../StandardDivider/StandardDivider";
import { CaseEventsFileInput } from "./CaseEventsFileInput";

interface CaseEventsProps {
  events: Array<EventOutputDTO>;
  editable?: boolean;
  isReporter?: boolean;
  hideMessageSubmit?: boolean;
  onSendMessage: (message?: string, attachment?: File) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    header: {
      padding: theme.spacing(3, 0, 3, 3)
    },
    list: {
      width: "100%",
      overflow: "auto",
      marginBottom: "96px"
    },
    messageInput: {
      padding: theme.spacing(3, 4, 1, 3),
      maxWidth: "800px"
    },
    dividerContainer: {
      padding: theme.spacing(0, 4, 0, 3)
    },
    caseTitle: {
      fontSize: 40,
      flexGrow: 1,
      fontWeight: "lighter",
      display: "flex",
      marginRight: theme.spacing(2)
    },
    fileHolder: {
      display: "flex",
      marginLeft: 40
    },
    fileLabel: {
      display: "flex",
      padding: theme.spacing(2, 1, 2, 3)
    }
  })
);

export const CaseEvents = (props: CaseEventsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [message, setMessage] = useState<string>();
  const [attachmentState, setAttachmentState] = useState<File>();
  const ref = React.useRef<HTMLInputElement>(null);
  const fileRef = React.useRef<File>(null);

  const handleSendMessage = (e: any) => {
    e.preventDefault();

    if (attachmentState !== undefined) {
      props.onSendMessage(undefined, attachmentState);
      setAttachmentState(undefined);
    }

    if (message !== undefined && message !== "") {
      props.onSendMessage(message);
      setMessage("");
    }
  };

  const handleAttachment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files !== null && files.length > 0) {
      setAttachmentState(files[0]);
    }
    event.target.value = "";
  };

  const handleClearFile = () => {
    setAttachmentState(undefined);
  };

  const handleFileButtonClick = () => {
    if (ref.current) {
      ref.current.click();
    }
  };
  return (
    <form>
      <Box textAlign="left" minHeight={"70vh"}>
        <List className={classes.list}>
          <EventList events={props.events} />
        </List>
        {props.hideMessageSubmit !== true && (<>
          <div className={classes.dividerContainer}>
            <StandardDivider />
          </div>
          <div className={classes.messageInput}>
            <SubTitle text={t("case.enterMessage")} />
            <Typography variant="body1">{t("case.helptext")}</Typography>

            <Box mt={4}>
              <InputTextField
                label={t("case.enterMessage")}
                value={message}
                placeholder={t("case.enterMessage")}
                multiline={true}
                ariaRequired={true}
                onChange={(event) => setMessage(event as string)}
              />
            </Box>
            <Box flexDirection="column" display="flex">
              <CaseEventsFileInput attachment={attachmentState} setAttachment={setAttachmentState} />
              <Button
                variant={"contained"}
                type="submit"
                size={"large"}
                onClick={handleSendMessage}
                color={"secondary"}
                style={{ marginTop: 20 }}
              >
                <span style={{ marginRight: 8 }}>{t("case.send")}</span>
                <SendIcon />
              </Button>
            </Box>
          </div>
        </>)}
      </Box>
    </form>
  );
};

const SubTitle = (props: TextProps) => {
  const { text } = props;
  if (text === undefined || text === null || text === "") return null;

  return (
    <Box marginBottom={2} marginTop={1}>
      <strong>{text}</strong>
    </Box>
  );
};

interface TextProps {
  text?: string;
}

