import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { CasesTable } from "./CasesTable";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchCases, fetchFeedback } from "../../store/casesReducer";
import { Loading } from "../Loading/Loading";
import { CaseSimpleOutputDTO } from "../../api";
import { SetCustomerTokenDialog } from "../SetCustomerTokenDialog/SetCustomerTokenDialog";
import { setCustomerToken } from "../../store/authReducer";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  caseTitle: {
    fontSize: 40,
    flexGrow: 1,
    fontWeight: "lighter",
    display: "flex",
    marginRight: theme.spacing(2),
  },
  root: {
    alignItems: "center",
    justifyContent: "center",
    width: "90%",
    padding: theme.spacing(3, 0, 3, 0),
  },
}));

interface CasesProps {
  feedback?: boolean;
}

export const Cases = (props: CasesProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const cases = useAppSelector((state) => state.cases.all);
  const caseStatus = useAppSelector((state) => state.cases.status);

  const [tokenDialogOpen, setTokenDialogOpen] = useState<boolean>(false);
  const [caseToHandle, setCaseToHandle] = useState<CaseSimpleOutputDTO>();

  const handleCloseDialog = () => {
    setTokenDialogOpen(false);
  };

  const handleSetPassphrase = (token: string) => {
    setTokenDialogOpen(false);
    dispatch(
      setCustomerToken({ customer: caseToHandle?.customerName ?? "", token })
    );
  };

  const isTokenSetForCustomer = useAppSelector(
    (state) =>
      state.auth.customerCredentials?.customer === caseToHandle?.customerName
  );

  useEffect(() => {
    if (props.feedback) {
      dispatch(fetchFeedback());
    } else {
      dispatch(fetchCases());
    }
  }, [props.feedback, dispatch]);

  const shouldShowLoading = () => {
    switch (caseStatus) {
      case "LOADING_CASES":
        return true;

      default:
        return false;
    }
  };

  const onHandleCase = (caseToHandle: CaseSimpleOutputDTO) => {
    setCaseToHandle(caseToHandle);
  };

  useEffect(() => {
    if (caseToHandle !== undefined) {
      if (isTokenSetForCustomer) {
        // Open case if token is set
        history.push(`/cases/${caseToHandle?.id}`);
      } else {
        // Open dialog if token is not set
        setTokenDialogOpen(true);
      }
    }
  }, [isTokenSetForCustomer, caseToHandle, history]);

  return (
    <div>
      {shouldShowLoading() ? <Loading /> : null}
      <Toolbar className={classes.root}>
        <Typography className={classes.caseTitle} noWrap>
          {t("cases.title")}
        </Typography>
      </Toolbar>
      <CasesTable cases={cases} onHandleCase={onHandleCase} />
      <SetCustomerTokenDialog
        customer={caseToHandle?.customerName?.toString().replace("#", "") ?? ""}
        open={tokenDialogOpen}
        onClose={handleCloseDialog}
        onTokenSet={(passphrase) => handleSetPassphrase(passphrase)}
      />
    </div>
  );
};
