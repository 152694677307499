/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IncidentCategory {
    CORRUPTION = 'Corruption',
    CODE_OF_CONDUCT = 'Code_Of_Conduct',
    COMPETITION_ANTITRUST = 'Competition_Antitrust',
    ENVIRONMENTAL_PROTECTION = 'Environmental_Protection',
    ACCOUNTING = 'Accounting',
    WORK_AND_HEALTH_PROTECTION = 'Work_And_Health_Protection',
    OTHER = 'Other',
    UNSURE = 'Unsure',
    FEEDBACK = 'Feedback',
}