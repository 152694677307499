import React from "react";
import { Box, Button } from "@material-ui/core";
import { EditWithChoiceButtonProps } from "./EditWithChoiceButton";
import { ChoiceMultipleDialog } from "./ChoiceMultipleDialog";

interface EditWithMultipleChoiceButtonProps<ChoiceType> extends EditWithChoiceButtonProps<ChoiceType[]> {
  defaultSelection?: ChoiceType[]
}

export function EditWithMultipleChoiceButton <T>(props: EditWithMultipleChoiceButtonProps<T>) {
  const [open, setOpen] = React.useState(false);

  const handleOnChoice = (value: T[] | null) => props.onChoice(value);
  const handleOnClose = () => setOpen(false);

  if (!props.editable) return <>{props.children}</>;
  return (
    <>
      <Button fullWidth={true} variant="outlined" onClick={() => setOpen(true)}>
        <Box textAlign="left">{props.children}</Box>
      </Button>
      <ChoiceMultipleDialog
        defaultSelection={props.defaultSelection}
        open={open}
        onChoice={(value) => handleOnChoice(value)}
        onClose={handleOnClose}
        title={props.title}
        getChoices={props.getChoices}
      />
    </>
  );
};
