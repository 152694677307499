import React from 'react';
import { AttachmentOutputDTO, CaseOutputDTO, OpenAPI } from '../../api';

export const HandleDownload = (
  event: React.MouseEvent<Element, MouseEvent>,
  attachment: AttachmentOutputDTO,
  caseDetails: CaseOutputDTO,
  customerToken?: string
) => {

  let headersInit: HeadersInit = {};
  let auth: string;
  if (OpenAPI.TOKEN === undefined) { 
    auth = "Basic " + btoa(OpenAPI.USERNAME + ":" + OpenAPI.PASSWORD);
    headersInit = {
      Authorization: auth,
      "SignedInCustomer": `${OpenAPI.SIGNEDINCUSTOMERID}`
    }
  }
  else { 
    auth = `Bearer ${OpenAPI.TOKEN}` 
    if (customerToken) {
      headersInit = {
        Authorization: auth,
        "Customer-Token": customerToken,
        "SignedInCustomer": `${OpenAPI.SIGNEDINCUSTOMERID}`
      }
    }
    
  };
  // The API client is having problems with Blobs so have to do this call manually :(
  const url = `${OpenAPI.BASE}/api/Cases/${caseDetails.id}/Attachments/${attachment.id}`;
  fetch(url, {
    headers: headersInit,
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      if (window !== null) {
        let link = document.createElement("a");
        link.href = url;
        link.download = attachment.filename || "";
        link.click();
      }
      URL.revokeObjectURL(url);
    });

  event.preventDefault();
};


