import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchUserLog } from "../../store/customersReducer";
import { Loading } from "../Loading/Loading";
import { LogTable } from "../LogTable/LogTable";

interface CustomerLogProps {
  customerId: string;
}

export const CustomerLog = (props: CustomerLogProps) => {
  const dispatch = useAppDispatch();
  const customerLog = useAppSelector((state) => state.customers.AuditLog);
  const loading = useAppSelector(
    (state) => state.customers.logStatus === "LOG_NOT_LOADED"
  );

  useEffect(() => {
    if (loading) {
      dispatch(fetchUserLog(props.customerId));
    }
  }, [loading, props.customerId, dispatch]);

  if (loading) return <Loading />;

  return <LogTable contentArray={customerLog} />;
};
