import React, { ReactNode } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { ListItemIcon, ListItemText } from "@material-ui/core";

interface MenuButtonProps {
  text: string;
  onClick(): void;
  icon: ReactNode;
}
const MenuButton = (props: MenuButtonProps) => {
  return (
    <MenuItem button key={props.text} onClick={props.onClick}>
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText primary={props.text} />
    </MenuItem>
  );
};

export default MenuButton;
