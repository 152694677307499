/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserDTO } from '../models/UserDTO';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * Get current signed in user
     * @returns UserDTO Returns a User found by id
     * @throws ApiError
     */
    public static async getCurrentUser(): Promise<UserDTO> {
        const result = await __request({
            method: 'GET',
            path: `/api/Users/current`,
            errors: {
                400: `If the item is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
                404: `If User not found`,
            },
        });
        return result.body;
    }

    /**
     * Save signed-in log to customer
     * @param customerId 
     * @returns any Success
     * @throws ApiError
     */
    public static async logSignedInCustomer(
customerId: string,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/Users/${customerId}`,
            errors: {
                400: `If the item is null`,
                401: `Unauthorized`,
                403: `Insufficient privileges`,
            },
        });
        return result.body;
    }

}