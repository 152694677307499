import { Option } from "../types/Option";
import { TFunction } from "i18next";

export const stringsToOptions = (strings: string[]): Option[] =>
  strings.map((string) => ({ value: string, text: string }));

  export const logTypeToString = (string: string, t: TFunction) => {
    switch (string) {
      case "Signin":
        return t("logTypes.signin");

      case "Create":
        return t("logTypes.create");

      case "Read":
        return t("logTypes.read");

      case "Update":
        return t("logTypes.update");

      case "Delete": 
        return t("logTypes.delete");
    
      default:
        return string;
    }
  }

  export const renameReporter = (user: string | null | undefined, t: TFunction) => {
    var reporterText = user;
  
    if(user !== undefined && user !== null && user.toLowerCase() === "reporter"){
          reporterText = t(`reporter.reporter`);
      }
  
    return reporterText;
  };
  