import { CaseInputDTO, CaseOutputDTO, CaseStatus } from "../api";
import { NewCaseInputDTO, ReporterState } from "../store/reporterReducer";

export function reporterStateToCaseInputDTO(
  customerId: string,
  state: ReporterState
): NewCaseInputDTO {
  return {
    CustomerId: customerId,
    IncidentCategory: state.type,
    Message: createContentString(state.date, state.metadata, state.description),
    Contact: createContactString(
      state.contactName,
      state.contactPhone,
      state.contactEmail
    ),
    Attachments: state.attachment ? [state.attachment] : [],
  };
}

const createContactString = (name?: string, phone?: string, email?: string) => {
  if (!name && !phone && !email) return undefined;
  else {
    let result = "";
    if (name) result += name + "\n";
    if (phone) result += phone + "\n";
    if (email) result += email + "\n";

    return result.trim();
  }
};

const createContentString = (
  date?: string,
  metadata?: string,
  description?: string
) => {
  if (!date && !metadata && !description) return undefined;
  else {
    let result = "";
    if (date) result += date + "\n\n";
    if (metadata) result += metadata + "\n\n";
    if (description) result += description + "\n\n";

    return result.trim();
  }
};

export function newCaseInputDTOToFormData(input: NewCaseInputDTO): FormData {
  const formData = new FormData();

  formData.append("CustomerId", input.CustomerId);
  formData.append("IncidentCategory", input.IncidentCategory ?? "");
  formData.append("Message", input.Message ?? "");
  if (input.Contact != null) {
    formData.append("Contact", input.Contact ?? "");
  }

  if (input.Attachments !== null) {
    for (let file of input.Attachments) {
      formData.append("Attachments", file);
    }
  }

  return formData;
}

export function caseOutputDTOToInputDTO(
  caseOutputDTO: CaseOutputDTO
): CaseInputDTO {
  return {
    id: caseOutputDTO.id,
    incidentCategory: caseOutputDTO.incidentCategory,
    caseStatus: caseOutputDTO.caseStatus,
    administrativeOfficer: caseOutputDTO.administrativeOfficer,
    viewers: caseOutputDTO.caseViewers
  };
}

export function getStatusColor(caseStatus?: CaseStatus) {
  switch (caseStatus?.toLowerCase()) {
    case "new":
      return "green";
    case "initial_judgement":
      return "pink";
    case "under_investigation":
      return "salmon";
    case "under_main_investigation":
      return "red";
    case "written_off":
    case "closed":
      return "black";
    default:
      return "black";
  }
}
