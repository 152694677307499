import { useEffect } from 'react';
import { useLocation, withRouter } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
      document.getElementById('main')?.focus();
  }, [pathname]);

  return null;
}