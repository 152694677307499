import { Avatar, Box, Button, makeStyles } from "@material-ui/core";
import React, { Ref, RefObject, useState } from "react";
import { useTranslation } from "react-i18next";

import AttachFileIcon from "@material-ui/icons/AttachFile";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    color: "#425466",
  },
  avatar: {
    backgroundColor: "#FFEEF1",
  },
  info: {
    flexGrow: 1,
  },
  description: {
    color: theme.custom.textBodyLight,
  },
  clearIcon: {
    float: "right",
    position: "relative",
  },
}));

interface CaseEventsFileInputProps {
  attachment?: File,
  setAttachment: Function
}

export const CaseEventsFileInput = (props: CaseEventsFileInputProps) => {
  const classes = useStyles();

  const { t } = useTranslation("reporter");
  let label = t("report.metadata.attachments.label");
  let helpText = t("report.metadata.attachments.helptext");

  // let [attachment, setAttachment] = useState<File>();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleAttachment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files !== null && files.length > 0) {
      props.setAttachment(files[0]);
    }
  };

  const handleClearFile = (event: React.MouseEvent<SVGSVGElement>) => {
    event.preventDefault();
    props.setAttachment(null);
  };

  if (props.attachment) {
    label = props.attachment.name;
    helpText = props.attachment.type;
  }

  function browseFiles(){
    if(inputRef.current){
      inputRef.current.click();
    }
  }

  return (
    <Box>
      <Box mb={2}>
        <input
          accept=".pdf,.doc,.docx,.xlsx,.jpg,.jpeg,.png,.mp4,.mov"
          id="upload-attachments"
          type="file"
          hidden
          ref={inputRef}
          onChange={handleAttachment}
        />
        <label htmlFor="upload-attachments">
          <Button fullWidth className={classes.button} onClick={() => browseFiles()}>
            <Box pt={0.5} component="span">
              <Avatar className={classes.avatar}>
                <AttachFileIcon className={classes.icon} />
              </Avatar>
            </Box>
            <Box marginLeft={1.5} textAlign="left" className={classes.info} component="span">
              <Box fontSize="16" component="span">{label}</Box>
              <Box
                fontSize="14"
                fontWeight="400"
                className={classes.description}
                style={{display: 'block'}}
                component="span"
              >
                {helpText}
              </Box>
            </Box>
            {props.attachment ? (
              <ClearIcon
                className={classes.clearIcon}
                onClick={(event) => handleClearFile(event)}
              />
            ) : null}
          </Button>
        </label>
      </Box>
    </Box>
  );
};
