import {
  BrowserCacheLocation,
  Configuration,
  PopupRequest,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { SyntheticEvent, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import { CustomerDTO } from "../../api/models/CustomerDTO";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  logIn,
  logSignedInCustomer,
  setSignedInCustomerId,
  setToken,
} from "../../store/authReducer";
import {
  fetchCustomerBrandning,
  setCustomerId,
} from "../../store/brandingReducer";
import { getLanguageCode } from "../LanguageSwitcher/LanguageSwitcher";
import { Loading } from "../Loading/Loading";
import { ChoseCustomer } from "../LogIn/ChoseCustomer";
import LogIn from "../LogIn/LogIn";
import MainApp from "./MainApp";
import OpenIdLogIn from "../LogIn/OpenIdLogin";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { User } from "oidc-client-ts";

const loginRequest: PopupRequest = {
  scopes: ["openid", "email"],
};

export const AuthenicatedApp = () => {
  const openIdConfig: AuthProviderProps = {
    client_id: process.env.REACT_APP_OPEN_ID_BOLAGSVERKET_CLIENT_ID ?? "visselblasning",
    authority: process.env.REACT_APP_OPEN_ID_BOLAGSVERKET_URL ?? "https://auth2-accept.bolagsverket.se/auth/realms/bol-external",
    scope: "email",
    redirect_uri: window.location.origin,
    onSigninCallback: (user: User | void) => {
      if (user) {
        dispatch(setToken(user.access_token));
        dispatch(logIn());
      }
    },
  };

  const msalConfig: Configuration = {
    auth: {
      clientId: "783d7a21-4bee-426b-9412-56534a11da0e",
      authority: "https://login.microsoftonline.com/common",
    },
    cache: {
      cacheLocation: BrowserCacheLocation.MemoryStorage, // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  };

  const mapToCustomerDTO = (
    customerId: string,
    customerName: string
  ): CustomerDTO => {
    let customer: CustomerDTO = {
      id: customerId,
      name: customerName,
    };
    return customer;
  };

  const msalInstance = new PublicClientApplication(msalConfig);
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  function onLogin(e: SyntheticEvent) {
    e.preventDefault();
    msalInstance
      .loginPopup(loginRequest)
      .then((response) => dispatch(setToken(response.idToken)))
      .then(() => dispatch(logIn()));
  }

  const authStatus = useAppSelector((state) => state.auth.status);
  const brandingStatus = useAppSelector((state) => state.branding.status);
  const defaultLanguage = useAppSelector(
    (state) => state.branding.branding?.defaultLanguage
  );
  let isDefaultLanugageLoaded = useRef(false);
  const loading =
    authStatus === "LOGGING_IN" || brandingStatus === "LOADING_BRANDING";
  const loggedIn = authStatus === "LOGGED_IN";
  const brandingLoaded = brandingStatus === "LOADED";
  const userCustomer = useAppSelector((state) => state.auth.signedInCustomerId);

  const user = useAppSelector((state) => state.auth.user);
  const listOfCustomers = useAppSelector((state) =>
    state.auth.user?.customersAndRoles?.map((x) =>
      mapToCustomerDTO(x.customerId!, x.customerName!)
    )
  );
  const loggingInCheckCustomer = authStatus === "LOGGING_IN_CHECK_CUSTOMER";
  const userGotMultipleCustomers =
    user != null &&
    user.customersAndRoles != null &&
    user.customersAndRoles.length >= 2;

  useEffect(() => {
    if (loggingInCheckCustomer) {
      if (
        user != null &&
        user.customersAndRoles != null &&
        !userGotMultipleCustomers
      ) {
        dispatch(setSignedInCustomerId(user.customersAndRoles[0].customerId!));
        dispatch(logSignedInCustomer(user.customersAndRoles[0].customerId!));
      }
    }
  }, [loggingInCheckCustomer, user, userGotMultipleCustomers, dispatch]);

  useEffect(() => {
    if (loggedIn && userCustomer != null) {
      dispatch(setCustomerId(userCustomer));
      dispatch(fetchCustomerBrandning());
    }
  }, [loggedIn, userCustomer, dispatch]);

  useEffect(() => {
    if (
      brandingStatus === "LOADED" &&
      !isDefaultLanugageLoaded.current &&
      defaultLanguage !== undefined
    ) {
      let defaultLanguageCode = getLanguageCode(defaultLanguage);
      i18n.changeLanguage(defaultLanguageCode);
      isDefaultLanugageLoaded.current = true;
    }
  }, [i18n, defaultLanguage, brandingStatus]);

  return (
    <AuthProvider {...openIdConfig}>
      <Switch>
        <Route path="/bolagsverket">
          <OpenIdLogIn />
        </Route>
        <Route>
          <MsalProvider instance={msalInstance}>
            {loading ? <Loading /> : null}
            {loggedIn && brandingLoaded ? (
              <MainApp />
            ) : loggingInCheckCustomer && userGotMultipleCustomers ? (
              <ChoseCustomer customers={listOfCustomers!} />
            ) : (
              <LogIn onLogin={onLogin} />
            )}
          </MsalProvider>
        </Route>
      </Switch>
    </AuthProvider>
  );
};
