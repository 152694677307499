import React from "react";
import { makeStyles, ThemeProvider, Typography } from "@material-ui/core";
import { useBranding } from "../../hooks/useBranding";

const useStyles = makeStyles((theme) => ({
  companyLogoContainer: {
    width: "100%",
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  logo: {
    width: "100%",
  },
}));

const CompanyLogo = () => {
  const classes = useStyles();
  const branding = useBranding();

  return (
    <ThemeProvider theme={branding.theme}>
      <div
        className={classes.companyLogoContainer}
        style={{ backgroundColor: branding.color! }}
      >
        <img
          className={classes.logo}
          src={branding.logotype!}
          alt={branding.name!}
        />
        <Typography variant="body2">{branding.name!}</Typography>
      </div>
    </ThemeProvider>
  );
};
export default CompanyLogo;
