/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Language {
    SWEDISH = 'Swedish',
    DANISH = 'Danish',
    NORWEGIAN = 'Norwegian',
    GERMAN = 'German',
    ENGLISH = 'English',
    FINNISH = 'Finnish',
    POLISH = 'Polish',
    SPANISH = 'Spanish'
}